import * as nodeTypes from './constants/nodes';
import * as messageTypes from './constants/messages';
import * as colorLibrary from './constants/colorScales';
import { getFlatDataFromTree, changeNodeAtPath, addNodeUnderParent, removeNode as removeNodeAtPath, insertNode } from 'react-sortable-tree';
import { generateNode } from '../factories';;

/*
  Should recieve an object
  Given a node, return its type based on
  -if it has children,
  -if it has cases,
  -if it uses scales
  should return a string of either: PARENT, DEFAULT, CASES, SCALES, CASES_SCALES
*/
export const resolveNodeType = (node) => {
  const { cases, scales, useCases, useScales, children } = node;
  if(children && children.length){ 
    return nodeTypes.PARENT;
  }
  if(useCases && cases.length && useScales && scales.length){
    return nodeTypes.CASES_SCALES;
  }
  if(useCases && cases.length){ 
    return nodeTypes.CASES;
  }
  if(useScales && scales.length){ 
    return nodeTypes.SCALES;
  }
  return nodeTypes.DEFAULT;
};

export const resolveLookupKey = (node) => {
  const nodeType = resolveNodeType(node);
  /*
    Parent nodes cant hold value so we return null
  */
  if(nodeType === nodeTypes.PARENT){
    return null
  }
  /*
    if the node uses cases, the look up case sould be the key that is selected, 
    OR 
    the first item 
    OR if the user wants to see all
  */
  if(nodeType === nodeType.CASES){

  }
  /* 
    by default, the nodd id acts as the lookup key 
  */
  return node.id
}

/*
  Should recieve an item
  Given a single case, check if it has been filled out properly
  returns an array of objects or an empty array
  example: [{severity: 'WARNING', message: 'You did not provide a title'}, {severity: 'WARNING', message: 'You did not provide a comment.'}]
  expected return array
*/
export const checkCase = (item) => {
  let recs = [];
  if(!item.title){
    recs.push({type: messageTypes.WARNING , message: messageTypes.CASE_TITLE});
  }
  if(!item.comment){
    recs.push({type: messageTypes.WARNING , message: messageTypes.CASE_COMMENT});
  }
  return recs;
};

/*
  Should recieve an object
  Given a single scale, check if it has been filled out properly
  returns an array of objects or an empty array
  example: [{severity: 'WARNING', message: 'You did not provide a title'}, {severity: 'ERROR', message: 'You did not provide a value. This will cause errors.'}]
  expected return array
*/
export const checkScales = (item) => {
  let recs = [];
  if(!item.title){
    recs.push({type: messageTypes.WARNING , message: messageTypes.SCALE_TITLE});
  }
  if(!item.comment){
    recs.push({type: messageTypes.WARNING , message: messageTypes.SCALE_COMMENT});
  }
  if(!item.value){
    recs.push({type: messageTypes.ERROR , message: messageTypes.SCALE_VALUE});
  }
  return recs;
};

/* 
  Should recieve an array
  Itterate each node in the tree, if any node that is not 
  eliminated is focused return true, else false.
  we can immediately return when we find one that is focued
  the tree needs to be flattened. Expect to recieve it nested
  Expected to return a boolean
*/
export const areNodesFocused = (tree) => {
  // flatten the tree here
  return tree.filter(({eliminated, focused}) => !eliminated && focused).length > 0;
};

/* 
  Should recieve and array
  Itterate each alternative, if any alternative that is not 
  eliminated is focused return true, else return false
  only one needs to be focused, we can immediatey return once we find one
  expected return boolean
*/
export const areAlternativesFocused = (alternatives) => {
  return alternatives.filter(({eliminated, focused}) => !eliminated && focused).length > 0;
};

/*
  Should recieve an array
  Itterate the tree an count the amount of eliminated nodes
  tree must be flattened first. Expect to recieve it nested
  expected return integer
*/
export const countEliminatedNodes = (tree) => {
  return tree.filter(({eliminated}) => eliminated).length;
};

/*
  Should recieve an array
  Itterate the alternatives an count the amount of eliminated
  expected return integer
*/
export const countEliminatedAlts = (alternatives) => {
  return alternatives.filter(({eliminated}) => eliminated).length;
};


/*
  Given an array, the index to update, and the changes to apply
*/
export const updateObjectInArray = (array, index, changes) => {
  return [
    ...array.slice(0, index),
    { ...array[index], ...changes },
    ...array.slice(index + 1)
  ]
};

/*
  Given an array, and items id, and the changes to be made to the object
*/ 
export const updateObjectInArrayById = (array, id, changes) => {
  return array.map(item => {
    if(item.id === id){
      return {
        ...item, ...changes
      }
    } else {
      return item
    }
  })
}

/*
  recieves a value as a number,
  allValues is an array of numbers,
  buckets is an integer for how many buckeys
  use the min and max andbuckets to determine how big the buckets are.
  e.g 0-100 with 5 buckets = 0-20, 20-40, 40-60, 60-80, 80-100;
  each bucket cooresponds to a background color.
  e.g. being in the 0-20 bucket could return {backgroundColor: 'red'}
  the 80-100 bucket could return {backgroundColor: 'green'}
*/

export const resolveCellStyle = (value, allValues, buckets, direction) => {
  let max = Math.max(...allValues); // the highest number from allValues
  let min = Math.min(...allValues); // the lowest number from allValues
  let increment = (max - min) / buckets;
  let style = {};
  for(let i = 1; i <= buckets; i++){
    let ceiling = (min + (increment * i));
    if(value <= ceiling){
      return colorLibrary.resolveColorScale('divergent', 2, buckets, (i - 1), direction);
      // return { backgroundColor, color }
    }
  };
  return style
}

/*
  when given an array, oldIndex and newIndex, moves the element to the new index
*/

export const arrayMove = (arr, oldIndex, newIndex) => {
	if (newIndex >= arr.length) {
		var k = newIndex - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
	return arr;
};


/* 
  given a tree, flatten it 
*/

export const getNodeKey = ({node}) => node.id

export const flattenTree = (treeData, ignoreCollapsed=false) => {
  return getFlatDataFromTree({treeData, getNodeKey, ignoreCollapsed})
}

/* 
  in order to change a node in the tree, we need to pass it the current tree, path, and the updated node
  then we return the whole tree
*/
export const editNodeInTree = (treeData, path, newNode) => {
  return changeNodeAtPath({treeData, path, getNodeKey, newNode, ignoreCollapsed: true})
}

/*
  Take the current tree, and add a node unde the parent provided
*/
export const addChildNode = (treeData, parentKey) => {
  return addNodeUnderParent({
    treeData,
    parentKey,
    newNode: generateNode(Math.floor(Math.random() * 1000000)), 
    getNodeKey,
    ignoreCollapsed: false,
    expandParent: true,
    addAsFirstChild: true
  }).treeData
}

/*
  given a tree and path, remove the node at the path
*/
export const removeNode = (treeData, path) => {
  return removeNodeAtPath({
    treeData,
    path,
    getNodeKey: ({treeIndex}) => treeIndex,
    ignoreCollapsed: false
  }).treeData
}

/* 
  Adds a node directly under a node
*/
export const addSiblingNode = (treeData, depth, minimumTreeIndex) => {
  return insertNode({
    treeData,
    depth,
    minimumTreeIndex,
    newNode: generateNode(Math.floor(Math.random() * 1000)), 
    getNodeKey,
    ignoreCollapsed: true,
    expandParent: false,
  }).treeData
}

/* returns a node with a given id or null */
export const findNodeAtId = (tree, id) => {
  let found = null;
  const flat = flattenTree(tree, false);
  for(let i = 0; i < flat.length; i++){
    if(flat[i].node.id === id){
      found = {node: flat[i].node, path: flat[i].path};
      break;
    }
  }
  return found
}