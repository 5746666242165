import React from 'react';
import { Icon } from '@fluentui/react/lib/Icon';
import HarveyBall from '../../pages/components/HarveyBall';

const highMedLow = [
  {
    key: '3',
    text: 'High',
    value: 3
  },
  {
    key: '2',
    text: 'Medium',
    value: 2
  },
  {
    key: '1',
    text: 'Low',
    value: 1
  }
]

const threeReg = [
  {
    key: '1',
    text: '1',
    value: 1
  },
  {
    key: '2',
    text: '2',
    value: 2
  },
  {
    key: '3',
    text: '3',
    value: 3
  }
];

const threeZero = [
  {
    key: '-1',
    text: '-1',
    value: -1
  },
  {
    key: '0',
    text: '0',
    value: 0
  },
  {
    key: '1',
    text: '1',
    value: 1
  }
];

const harveys = [
  {
    key: '1',
    render: (size=24) => <HarveyBall value={0} size={size} />,
    value: 1
  },
  {
    key: '2',
    render: (size=24) => <HarveyBall value={0.25} size={size} />,
    value: 2
  },
  {
    key: '3',
    render: (size=24) => <HarveyBall value={0.5} size={size} />,
    value: 3
  },
  {
    key: '4',
    render: (size=24) => <HarveyBall value={0.75} size={size} />,
    value: 4
  },
  {
    key: '5',
    render: (size=24) => <HarveyBall value={1} size={size} />,
    value: 5
  }
];

const fiveReg = [
  {
    key: '1',
    text: '1',
    value: 1
  },
  {
    key: '2',
    text: '2',
    value: 2
  },
  {
    key: '3',
    text: '3',
    value: 3
  },
  {
    key: '4',
    text: '4',
    value: 4
  },
  {
    key: '5',
    text: '5',
    value: 5
  }
];

const fiveZero = [
  {
    key: '-2',
    text: '-2',
    value: -2
  },
  {
    key: '-1',
    text: '-1',
    value: -1
  },
  {
    key: '0',
    text: '0',
    value: 0
  },
  {
    key: '1',
    text: '1',
    value: 1
  },
  {
    key: '2',
    text: '2',
    value: 2
  }
];

const sevenReg = [
  {
    key: '1',
    text: '1',
    value: 1
  },
  {
    key: '2',
    text: '2',
    value: 2
  },
  {
    key: '3',
    text: '3',
    value: 3
  },
  {
    key: '4',
    text: '4',
    value: 4
  },
  {
    key: '5',
    text: '5',
    value: 5
  },
  {
    key: '6',
    text: '6',
    value: 6
  },
  {
    key: '7',
    text: '7',
    value: 7
  }
];

const sevenZero = [
  {
    key: '-3',
    text: '-3',
    value: -3
  },
  {
    key: '-2',
    text: '-2',
    value: -2
  },
  {
    key: '-1',
    text: '-1',
    value: -1
  },
  {
    key: '0',
    text: '0',
    value: 0
  },
  {
    key: '1',
    text: '1',
    value: 1
  },
  {
    key: '2',
    text: '2',
    value: 2
  },
  {
    key: '3',
    text: '3',
    value: 3
  }
];

export const ratingOptions = [
  {
    key: 'FavoriteStarFill',
    text: 'Star',
  },
  {
    key: 'HeartFill',
    text: 'Heart',
  },
  {
    key: 'World',
    text: 'Earth'
  },
  {
    key: 'Money',
    text: 'Money'
  },
  {
    key: 'LikeSolid',
    text: 'Thumbs Up'
  },
  {
    key: 'DislikeSolid',
    text: 'Thumbs Down'
  },
  {
    key: 'LockSolid',
    text: 'Lock'
  },
  {
    key: 'Suitcase',
    text: 'Jobs'
  }
]

export const scaleOptions = [
  {
    key: 'harvey',
    text: 'Harvey Balls',
    scales: harveys
  },
  {
    key: 'high-med-low',
    text: 'High, Medium, Low',
    scales: highMedLow
  },
  {
    key: 'three-reg',
    text: '3 point default',
    scales: threeReg
  },
  {
    key: 'three-zero',
    text: '3 point 0 based',
    scales: threeZero
  },
  {
    key: 'five-reg',
    text: '5 point default',
    scales: fiveReg
  },
  {
    key: 'five-zero',
    text: '5 point 0 based',
    scales: fiveZero
  },
  {
    key: 'seven-reg',
    text: '7 point default',
    scales: sevenReg
  },
  {
    key: 'seven-zero',
    text: '7 point 0 based',
    scales: sevenZero
  }
];

export const nodeTypes = [
  {
    key: 'number',
    text: 'Number'
  },
  {
    key: 'scale',
    text: 'Scale'
  },
  {
    key: 'icon',
    text: 'Icon'
  },
  // {
  //   key: 'rating',
  //   text: 'Rating'
  // }
];

export const thumbThree = [
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: 3,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
    </React.Fragment>
  }
];

export const moneyThree = [
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-usd" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-usd" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-usd" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: 3,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-usd" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-usd" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-usd" aria-hidden="true"></i>
    </React.Fragment>
  }
];

export const thumbDownThree = [
  {
    key: '1',
    text: '1',
    value: -1,
    render: () => <React.Fragment>
      {/* <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i> */}
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: -2,
    render: () => <React.Fragment>
      {/* <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i> */}
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: -3,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
    </React.Fragment>
  }
];

export const thumbUpDownThree = [
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <i className="fa fa-minus" style={{marginRight: 2, marginLeft: 2}}></i>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: 3,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
    </React.Fragment>
  }
];

export const thumbFive = [
  {
    key: '-2',
    text: '-2',
    value: -2,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '-1',
    text: '-1',
    value: -1,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '0',
    text: '0',
    value: 0,
    render: () => <React.Fragment>
      <i className="fa fa-minus" style={{marginRight: 2, marginLeft: 2}}></i>
    </React.Fragment>
  },
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
    </React.Fragment>
  }
];

export const thumbSeven = [
  {
    key: '-3',
    text: '-3',
    value: -3,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '-2',
    text: '-2',
    value: -2,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '-1',
    text: '-1',
    value: -1,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-down" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '0',
    text: '0',
    value: 0,
    render: () => <React.Fragment>
      <i className="fa fa-minus" style={{marginRight: 2, marginLeft: 2}}></i>
    </React.Fragment>
  },
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: 3,
    render: () => <React.Fragment>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
      <i style={{marginRight: 2, marginLeft: 2}} className="fa fa-thumbs-up" aria-hidden="true"></i>
    </React.Fragment>
  }
];

const starThree = [
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: 3,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  }
];

const starFive = [
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: 3,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '4',
    text: '4',
    value: 4,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '5',
    text: '5',
    value: 5,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  }
];

const starSeven = [
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: 3,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '4',
    text: '4',
    value: 4,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '5',
    text: '5',
    value: 5,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '6',
    text: '6',
    value: 6,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  },
  {
    key: '7',
    text: '7',
    value: 7,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='FavoriteStarFill'/>
    </React.Fragment>
  }
];

const emotionsThree = [
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='Sad'/>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='EmojiNeutral'/>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: 3,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='Emoji2'/>
    </React.Fragment>
  }
];

const emotionsFive = [
  {
    key: '1',
    text: '1',
    value: 1,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='EmojiDisappointed'/>
    </React.Fragment>
  },
  {
    key: '2',
    text: '2',
    value: 2,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='Sad'/>
    </React.Fragment>
  },
  {
    key: '3',
    text: '3',
    value: 3,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='EmojiNeutral'/>
    </React.Fragment>
  },
  {
    key: '4',
    text: '4',
    value: 4,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='Emoji2'/>
    </React.Fragment>
  },
  {
    key: '5',
    text: '5',
    value: 5,
    render: () => <React.Fragment>
      <Icon style={{marginRight: 2, marginLeft: 2}} iconName='Emoji'/>
    </React.Fragment>
  }
]

export const iconOptions = [
  {
    key: 'money-3',
    text: 'Money (3)',
    options: moneyThree
  },
  {
    key: 'thumb-3',
    text: 'Thumbs up (3)',
    options: thumbThree
  },
  {
    key: 'thumb-down-3',
    text: 'Thumbs down (3)',
    options: thumbDownThree
  },
  {
    key: 'thumb-up-down-3',
    text: 'Thumbs up / down (3)',
    options: thumbUpDownThree
  },
  {
    key: 'thumb-5',
    text: 'Thumbs up / down (5)',
    options: thumbFive
  },
  {
    key: 'thumb-7',
    text: 'Thumbs up / down (7)',
    options: thumbSeven
  },
  {
    key: 'star-3',
    text: 'Stars (3)',
    options: starThree
  },
  {
    key: 'star-5',
    text: 'Stars (5)',
    options: starFive
  },
  {
    key: 'star-7',
    text: 'Stars (7)',
    options: starSeven
  },
  {
    key: 'feeling',
    text: 'Emotion (3)',
    options: emotionsThree
  },
  {
    key: 'feeling-2',
    text: 'Emotion (5)',
    options: emotionsFive
  }
]