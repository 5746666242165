import { PrimaryButton, TextField } from '@fluentui/react';
import Axios from 'axios';
import { Dialog } from 'office-ui-fabric-react';
import React from 'react';

export default function ResetPassword(props){
  const [password, setPassword] = React.useState('');
  const [confirm, setConfirmPassword] = React.useState('');
  const [message, setMessage]  = React.useState(null);

  const submit = () => {
    // console.log(props.match.params);
    const { reset_hash } = props.match.params;
    if(password === confirm){
      Axios.post(`/api/v1/users/reset-password`, {password, confirm, reset_hash})
      .then(res => {
        if(res.data.success){
          setMessage("Your password was reset. Please sign in with your new password.");
        } else {
          throw Error()
        }
      })
      .catch(err => {
        setMessage("Sorry, your password was not able to be reset. Please contact cschroeder@compassrm.com.")
      })
    }
  }

  if(message) return message;

  return <div className='fr jcc aic h-100'>
    <div className='p2'>
      <TextField type='password' value={password} label="New Password" onChange={(e, value) => setPassword(value)} />
      <TextField type='password' className='mb-8' value={confirm} label="Confirm New Password" onChange={(e, value) => setConfirmPassword(value)} />
      <PrimaryButton text="Submit" onClick={submit} />
    </div>
  </div>
}