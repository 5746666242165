import React from 'react';

export default function Pill({size=24, value=0}){

  const outer = React.useMemo(() => {
    return {
      height: size,
      width: size,
      borderRadius: '50%',
      boxShadow: `0px 0px 0px 1px #333`,
      position: 'relative',
      overflow: 'hidden',
      background: '#fff'
    }
  }, [size]);

  const inner = {
    height: `${value * 100}%`,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: '#333'
  }

  return <div style={outer}>
    <div style={inner}></div>
  </div>

}