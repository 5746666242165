import * as projectActions from '../actions/projectActions';
import { updateObjectInArrayById } from '../../lib';
import { generateAlt, guideData } from '../../factories'

const initialState = {
	data: {},
	prev: {}
};

const reducer = (state = initialState, action) => {
	switch(action.type){
		case projectActions.INIT_GUIDE: {
			return {
				...state,
				data: guideData
			}
		}
		case projectActions.PROJECT_EDIT_DATA: {
			return {
				...state,
				data: {
					...state.data,
					...action.changes
				}
			}
		}
		case projectActions.EDIT_NODE: {
			return {
				...state,
				data: {
					...state.data,
					tree: state.data.tree.map(t => {
						if(t.id === action.id){
							return {
								...t,
								...action.changes
							}
						}
						return t
					})
				}
			}
		}
		case projectActions.PROJECT_SET_DATA: {
			return {
				...state,
				data: {
					...action.data
				},
				prev: {
					...action.prev
				}
			}
		}
		case projectActions.EDIT_ALTERNATIVE: {
			return {
				...state,
				data: {
					...state.data,
					alternatives: updateObjectInArrayById(state.data.alternatives, action.id, action.changes)
				}
			}
		}
		case projectActions.EDIT_ALTERNATIVES: {
			return {
				...state,
				data: {
					...state.data,
					alternatives: action.alternatives
				}
			}
		}
		case projectActions.ADD_ALTERNATIVE: {
			return {
				...state,
				data: {
					...state.data,
					alternatives: state.data.alternatives.concat(generateAlt())
				}
			}
		}
		case projectActions.EDIT_TREE: {
			return {
				...state,
				data: {
					...state.data,
					tree: action.tree
				}
			}
		}
		case projectActions.EDIT_ABOUT: {
			return {
				...state,
				data: {
					...state.data,
					about: action.about
				}
			}
		}
		default: return {...state}
	}
}

export default reducer;

