import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@fluentui/react/lib/TextField';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { IconButton, getTheme, TooltipHost, Icon } from '@fluentui/react';

import { editAlternative, toggleShowInstructions, editProjectUi } from '../../../store/actions';
import { scaleOptions, iconOptions } from '../../../lib/templates/scales';

// import Coach from '../../../components/Coach';
import { Rating } from '@fluentui/react/lib/Rating';
import ResizeCol from '../../components/ResizeCol';
import ToggleSlider from '../../../components/ToggleSlider';

const theme = getTheme();

function Consequences({history}){
	const dispatch = useDispatch();
	const { tree, alternatives } = useSelector(state => state.project.data);
	const { showInstructions, objColWidth, unitColWidth, dirColWidth, altColWidth } = useSelector(state => state.projectUi);
	const [side, setSide] = React.useState('left');

	const filteredTree = tree.filter(t => !t.eliminated);
	const filteredAlternatives = alternatives.filter(a => !a.eliminated);

	const objWidth = {width: objColWidth, minWidth: objColWidth, maxWidth: objColWidth};

	const unitWidth = {width: unitColWidth, minWidth: unitColWidth, maxWidth: unitColWidth};

	const dirWidth = {width: dirColWidth, minWidth: dirColWidth, maxWidth: dirColWidth};

	const altWidth = {width: altColWidth, minWidth: altColWidth, maxWidth: altColWidth, textAlign: 'center'}

	return (
		<React.Fragment>
			{/* <Coach page='consequences' history={history}/> */}

			<div className='page'>
				<h1 id='demo-13' className='section-header ms-fontWeight-semibold'>
					Consequences - How does each alternative perform for each objective?
					<IconButton 
						iconProps={{iconName: 'MoreVertical'}} 
						styles={{root: {background: 'none !important'}}} 
						onClick={() => dispatch(toggleShowInstructions(!showInstructions))}/>
				</h1>
				<div hidden={!showInstructions} className='mb-64'>
					<h3 className='ms-fontWeight-semibold'>Good decisions are based on sound evidence and reasoning. Gather the best available information about the consequences of each alternative for each objective. Learn how to identify reliable sources of information (e.g., websites, research, people with recognized expertise) and potential sources of bias, including your own.</h3>
				</div>

				<ToggleSlider left="Data" right="Notes" selectedSide={side} onChange={(side) => setSide(side)} />

						<div className='fr ms-fontWeight-semibold'>
							<div className='cell fr aie' style={objWidth}>
								Objective
							</div>
							<div className='cell fr aie' style={unitWidth}>
								Unit of Measure
							</div>
							<div className='cell fr aie' style={dirWidth}>
								Bigger Numbers are
							</div>
							{ 
								filteredAlternatives.map((alt) => {
									let {width=altColWidth} = alt;
									let altStyle = {width: width, minWidth: width, maxWidth: width};
									return <div className='cell fr aie' key={alt.id} style={altStyle}>
										<TooltipHost styles={{root: {width: '100%', textAlign: 'center'}}} content={alt.subTitle}>

											{alt.title || ' '}
										</TooltipHost>
									</div>
								})
							}
						</div>
						<ResizeCol showAll />
							{
								filteredTree.map((row, i) => {
									return <React.Fragment key={i}>
									<div className='fr mc ms-depth-16' style={{marginBottom: 6, background: '#fff'}} key={row.id}>
											<div className='cell' style={{width: objColWidth}}>
												{row.title || ' '}
												{row.description && <TooltipHost maxWidth={200} content={row.description}><Icon className='pointer' iconName="Info" styles={{root: {color: getTheme().palette.neutralTertiary, marginLeft: 6}}} /></TooltipHost>}
											</div>
											<div className='cell' style={{width: unitColWidth}}>
												{row.unit || ' '}
											</div>
											<div className='cell' style={{width: dirColWidth}}>
												{row.direction === 'higher' ? "Better" : "Worse"}
											</div>

											{
												filteredAlternatives.map(alt => {
													let {width=altColWidth} = alt;
													let altStyle = {width: width, minWidth: width, maxWidth: width};
													let cell = <TextField
													borderless
													styles={{field: {textAlign: 'center'}, fieldGroup: {background: !alt.data[row.id] ? theme.palette.themeLighter : '#fff'}}}
													autoComplete='off'
													value={alt.data[row.id]}
													onChange={(e, value) => dispatch(editAlternative(alt.id, {data: {...alt.data, [row.id]: value}}))}
													placeholder='-' />

													if(row.type === 'rating'){
														let { ratingIcon='FavoriteStarFill', maxRating=5 } = row; 
														cell = <Rating
															min={1}
															max={maxRating}
															rating={alt.data[row.id]}
															icon={ratingIcon}
															styles={{ratingStarBack: {opacity: '0.3'}, root: {textAlign: 'center'}}}
															unselectedIcon={ratingIcon}
															onChange={(e, rating) => dispatch(editAlternative(alt.id, {data: {...alt.data, [row.id]: rating.toString()}}))}
														/>
													}

													if(row.type === 'scale'){
														let selectedScaleOptions = scaleOptions.find(o => o.key === row.selectedScale) || scaleOptions[0];
														cell = <Dropdown
															selectedKey={alt.data[row.id]}
															onRenderTitle={(props) => {
																return props[0] && <div className='fr aic jcc h-100'>{props[0].render ? props[0].render(20) : props[0].text}</div>;
															}}
															onRenderOption={(props) => props.render ? props.render(20) : props.text}
															placeholder='-'
															options={selectedScaleOptions.scales}
															styles={{title: {border: 'none', textAlign: 'center', borderRadius: 0, padding: 0}}}
															onChange={(e, option) => dispatch(editAlternative(alt.id, {data: {...alt.data, [row.id]: option.key}}))}
														/>
													}

													if(row.type === 'icon'){
														let selectedIconOptions = iconOptions.find(o => o.key === row.selectedIcon) || iconOptions[0];
														cell = <Dropdown
															selectedKey={alt.data[row.id]}
															className='center-text'
															placeholder='-'
															options={selectedIconOptions.options}
															onRenderTitle={(props) => {
																if(props[0]){
																	return props[0].render()
																} else {
																	return null
																}
															}}
															onRenderOption={(props) => {
																return props.render();
															}}
															styles={{
																title: {
																	border: 'none', 
																	textAlign: 'center', 
																	borderRadius: 0, 
																	padding: 0,
																	fontSize: 20,
																}}}
															onChange={(e, option) => dispatch(editAlternative(alt.id, {data: {...alt.data, [row.id]: option.key}}))}
														/>
													}

													if(side === 'right'){
														cell = <TextField 
															value={alt.notes && alt.notes[row.id]}
															multiline
															styles={{
																fieldGroup: {background: alt.notes && alt.notes[row.id] ? '#fff' : theme.palette.themeLighter, height: '100% !important'},
																root: {height: '100%'},
																field: {height: '100%'},
																wrapper: {height: '100%'}
															}}
															autoAdjustHeight
															resizable={false}
															placeholder='...'
															borderless
															rows={3}
															onChange={(e, val) => dispatch(editAlternative(alt.id, {notes: {...alt.notes, [row.id]: val}}))} />
													}

													return (
														<div style={altStyle} key={`${row.id}-${alt.id}`}>
															{ cell }
														</div>
													)
												}
												)
											}
									</div>
									</React.Fragment>
								})
							}
				</div>
		</React.Fragment>
	)
}

export default Consequences