import * as actions from './projectUiActions';

export const setEditNode = (id) => ({
  type: actions.SET_EDIT_NODE,
  id
})

export const isGuide = (bool=false) => ({
  type: actions.SET_GUIDE,
  bool
});

export const setLoading = (loading) => ({
  type: actions.SET_LOADING,
  loading
})

export const setSelectedAltId = (id) => ({
  type: actions.SET_SELECTED_ALT_ID,
  id
})

export const setSaving = (saving) => ({
	type: actions.SAVING,
	saving
});

export const toggleShowInstructions = (bool) => ({
  type: actions.TOGGLE_SHOW_INSTRUCTIONS,
  bool
});

export const toggleShowMore = () => ({
  type: actions.TOGGLE_SHOW_MORE
});

export const toggleCtMethod = (method) => ({
  type: actions.TOGGLE_CT_METHOD,
  method
});

export const editProjectUi = (changes) => ({
  type: actions.EDIT_PROJECT_UI,
  changes
});

export const toggleCoach = (bool) => ({
  type: actions.TOGGLE_COACH,
  bool
});