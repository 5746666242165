import React from 'react';

export default function ToggleSlider(props){

  let leftToggle = React.useRef(null);
  let rightToggle = React.useRef(null);

  const [width, setWidth] = React.useState();

  React.useEffect(() => {
    console.log(leftToggle);
    setWidth(props.selectedSide === 'left' ? leftToggle.current.offsetWidth : rightToggle.current.offsetWidth);
  }, [props.selectedSide]);

  let selectedSideStyle = {
    width: width,
    height: '20px',
    background: '#fff',
    position: 'absolute'
  };

  if(props.selectedSide === 'left'){
    selectedSideStyle.left = 4;
  } else {
    selectedSideStyle.left = 4 + leftToggle.current.offsetWidth + 16;
  }

  const clickWrapper = () => props.onChange(props.selectedSide === 'left' ? 'right' : 'left');

  return <div draggable className='fr aic g16 toggle-wrapper' onClick={clickWrapper}>
    <div className='toggle-selected-bg ms-depth-4' style={selectedSideStyle}></div>
    <div className={props.selectedSide === 'left' ? 'picked toggle-btn' : 'toggle-btn'} onClick={clickWrapper} ref={leftToggle}>{props.left}</div>
    <div className={props.selectedSide === 'right' ? 'picked toggle-btn' : 'toggle-btn'} onClick={clickWrapper} ref={rightToggle}>{props.right}</div>
  </div>
}