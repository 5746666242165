import * as settingsActions from '../actions/settingsActions';

const initialState = {
    zoom: 1,
    rowHeight: 40,
    nodeColWidth: 150,
    altColWidth: 100,
    headerHeight: 50,
    buckets: 5
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case settingsActions.SETTINGS_SCALE: {
            return {
                ...state,
                zoom: action.zoom
            }
        }
        case settingsActions.SETTINGS_HEIGHT: {
            return {
                ...state,
                rowHeight: action.height
            }
        }
        default: return {...state}
    }
}

export default reducer;

