import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, DefaultButton } from '@fluentui/react/lib/Button';

import { editNode, editAlternative, toggleShowInstructions, editTree } from '../../../store/actions';
import { scaleOptions, iconOptions } from '../../../lib/templates/scales';
import { toggleCtMethod, editProjectUi } from '../../../store/actions/projectUi';
import { Label } from '@fluentui/react/lib/Label';
import { Rating } from '@fluentui/react/lib/Rating';
import { DialogFooter, DialogType, TeachingBubble, TooltipHost } from '@fluentui/react';
import { 
	interpolateBrBG,
	interpolatePRGn,
	interpolatePiYG,
	interpolatePuOr,
	interpolateRdBu,
	interpolateRdGy,
	interpolateRdYlBu,
	interpolateRdYlGn,
	interpolateSpectral,
	interpolateBlues,
	interpolateGreens,
	interpolateGreys,
	interpolateOranges,
	interpolatePurples,
	interpolateReds  } from 'd3-scale-chromatic'
import { COLOR_SCHEMES, convertColor, SELECT_ONE_COLOR_SCHEMES } from '../../../lib/constants';
import { Dialog } from '@fluentui/react/lib/Dialog';
import { Callout, DirectionalHint, FontIcon, getTheme, Slider, Link, Icon } from '@fluentui/react';
import StackedColumn, {colors} from './StackedColumn';
import ResizeCol from '../../components/ResizeCol';
import HarveyBall from '../../components/HarveyBall';
import Pill from '../../../components/Pill';
import Ring from '../../components/Ring';
import Dot from '../../components/Dot';


function baseInRange(number, start, end) {
  return number >= Math.min(start, end) && number < Math.max(start, end)
}

export function normalize(a, b, val, direction) {
	if(isNaN(val) || isNaN(a) || isNaN(b)){
		return 0;
	}
  if((b - a) === 0){
    return 0.5;
  };
	let norm = (val - a) / (b - a); 
	if(direction === 'lower') {
		norm = 1 - norm
	};
	// console.log(norm);
  return norm
};

function resolveLightness(colorString, method){
  let yiq, r, b, g;
  if(method === 'hex'){
    let hex = colorString.replace("#", "");
    r = parseInt(hex.substr(0,2),16);
    g = parseInt(hex.substr(2,2),16);
    b = parseInt(hex.substr(4,2),16);
    yiq = ((r*299)+(g*587)+(b*114))/1000;
  } else {
    // console.log(colorString);
    let rgb = colorString.slice(4, -1).split(', ');
    r = parseInt(rgb[0], 10);
    g = parseInt(rgb[1], 10);
    b = parseInt(rgb[2], 10);
    yiq = ((r*299)+(g*587)+(b*114))/1000;
  }
  return (yiq >= 128) ? '#333333' : '#ffffff';
};

function TradeOffs({history}){
	const dispatch = useDispatch();
	const { tree, alternatives } = useSelector(state => state.project.data);
	const { showInstructions, showMore, ctMethod, compAllScale, selectOneScale, objColWidth, altColWidth, unitColWidth, dirColWidth, showSelectOneIntro, showWeightingInstructions, showChart } = useSelector(state => state.projectUi);
	const [ selectedAltId, setSelectedAltId ] = useState(null);
	const [ showSettings, toggleShowSettings ] = useState(false);
	const [ showPicker, togglePicker ] = useState(false);
	const [ buffer ] = useState(0.10);
	const [ hoveredValue, setHoveredValue ] = useState(null);

	const filteredTree = tree.map((el, index) => ({...el, color: colors[index]}))
	.filter(t => !t.eliminated)
	.map(el => {
		let weight = parseFloat(el.weight);
		if(isNaN(weight)) return {...el, weight: 50};
		return {...el, weight};
	});

	const filteredAlternatives = alternatives.filter(a => !a.eliminated);
	const eliminatedObjectives = tree.filter(t => t.eliminated);
	const eliminatedAlternatives = alternatives.filter(a => a.eliminated);

	const rowValuesLookup = React.useMemo(() => {
		let holder = {};
		filteredTree.forEach(item => {
			holder = {
				...holder, 
				[item.id]: filteredAlternatives.map(alt => {
					let val = alt.data[item.id] || '';
					return {numVal: parseFloat(val.replace(/[^\d.-]/g, '')), text: val}
				}).filter(({numVal}) => !isNaN(numVal))
			}
		});
		return holder;
	}, [filteredTree, filteredAlternatives]);

	const rowWeightLookup = React.useMemo(() => {
		let holder = {};
		filteredTree.forEach(el => {
			let value = parseFloat(el.weight);
			if(isNaN(value)) return holder[el.id] = 50;
			holder[el.id] = value;
		});
		return holder;
	}, [filteredTree]);

	const weightedWeightLookup = React.useMemo(() => {
		let sum = Object.keys(rowWeightLookup).map(key => rowWeightLookup[key]).reduce((a, b) => a + b, 0);
		let holder = {};
		Object.keys(rowWeightLookup).forEach(key => {
			holder[key] = rowWeightLookup[key] / sum;
		});
		return holder;
	}, [rowWeightLookup]);

	// console.log(rowWeightLookup, rowValuesLookup, weightedWeightLookup);

	const selectedScale = React.useMemo(() => {
		return SELECT_ONE_COLOR_SCHEMES.find(s => s.key === selectOneScale).styleLookup;
	}, [selectOneScale]);

	const resolveNorm = React.useCallback((alt, objective) => {
		const min = Math.min(...rowValuesLookup[objective.id].map(el => el.numVal));
		const max = Math.max(...rowValuesLookup[objective.id].map(el => el.numVal));
		let value = alt.data[objective.id] || '';

		let parsedValue = parseFloat(value.replace(/[^\d.-]/g, ''));

		if(isNaN(parsedValue) || isNaN(min) || isNaN(max)){
			return null
		}
		
		return normalize(min, max, parsedValue, objective.direction);
	}, [rowValuesLookup]);

	const resolveCellStyle = React.useCallback((alt, objective) => {
		if(objective.type === 'icon'){
			objective.direction = 'higher';
		}

		if(ctMethod === 'select-one'){
			if(!selectedAltId){
				return {background: '#fff', color: 'inherit'}
			}
			
			const selectedAlt = filteredAlternatives.find(a => a.id === selectedAltId);
	
			if(!alt || !selectedAlt){
				return {background: '#fff', color: 'inherit'}
			}
	
			if(alt.id === selectedAltId){
				return {background: '#004578', color: '#fff'}
			} else {
				let value = alt.data[objective.id] || '';
				let parsedMsic = parseFloat(objective.msic);
				let selectedValue = selectedAlt.data[objective.id] || '';
				let parsedValue = parseFloat(value.replace(/[^\d.-]/g, ''));
				let parsedSelectedValue = parseFloat(selectedValue.replace(/[^\d.-]/g, ''));
				// check if the values are valid numbers
				if(isNaN(parsedValue) || isNaN(parsedSelectedValue)){
					return selectedScale.error
				}
	
				let ceiling = parsedSelectedValue + (parsedSelectedValue * (parsedMsic / 100));
				let floor = parsedSelectedValue - (parsedSelectedValue * (parsedMsic / 100));

				let isInRange = baseInRange(+parsedValue, +ceiling, +floor);
	
				if(isInRange){
					return selectedScale.same;
				}

	
				if(parsedValue > parsedSelectedValue){
					return objective.direction === 'higher' ? selectedScale.better : selectedScale.worse;
				}
				if(parsedValue < parsedSelectedValue){
					return objective.direction === 'higher' ? selectedScale.worse : selectedScale.better;
				}
			}
		} else {
			let norm = resolveNorm(alt, objective);

			console.log(norm);

			if (norm === null || isNaN(norm)) return {background: '#fff', color: '#d13438'};
		

			// error style
			// if(isNaN(norm)){
			// 	return {background: '#d2d0ce', color: '#d13438'}
			// };

			let startRange = 0.12;
			let totalRange = 0.76;

			let pecentFrom = norm * totalRange;

			let converted = startRange + pecentFrom;

			if(converted < 0.12){
				converted = 0.12;
			}
			if(converted > 0.88){
				converted = 0.88;
			}

			let background = '#fff';

			switch(compAllScale){
				case 'BrBG': 
					background = interpolateBrBG(converted);
					break;
				case 'PRGn': 
					background = interpolatePRGn(converted);
					break;
				case 'PiYG': 
					background = interpolatePiYG(converted);
					break;
				case 'PuOr': 
					background = interpolatePuOr(converted);
					break;
				case 'RdBu': 
					background = interpolateRdBu(converted);
					break;
				case 'RdGy': 
					background = interpolateRdGy(converted);
					break;
				case 'RdYlBu': 
					background = interpolateRdYlBu(converted); 
					break;
				case 'RdYlGn': 
					background = interpolateRdYlGn(converted); 
					break;
				case 'Spectral': 
					background = interpolateSpectral(converted);
					break;
				case 'Blues':
					background = interpolateBlues(converted); 
					break;
				case 'Greens':
					background = interpolateGreens(converted); 
					break;
				case 'Greys':
					background = interpolateGreys(converted); 
					break;
				case 'Oranges':
					background = interpolateOranges(converted);
					break;
				case 'Purples':
					background = interpolatePurples(converted);
					break;
				case 'Reds':
					background = interpolateReds(converted);
					break;
				default: 
					background = interpolateBrBG(converted);
					break;
			}

			return {
				background,
				color: resolveLightness(background, 'rbg'),
				norm
			}

			

		}
		return {};
	}, [selectedAltId, rowValuesLookup, filteredAlternatives, ctMethod, compAllScale, selectedScale, buffer]);

	const resolveMinMax = React.useCallback(({id, direction, type, selectedScale, selectedIcon}) => {

		// console.log(type);

		let min = Math.min(...rowValuesLookup[id].map(v => v.numVal).filter(v => !isNaN(v)));
		let max = Math.max(...rowValuesLookup[id].map(v => v.numVal).filter(v => !isNaN(v)));

		let indexOfMin = rowValuesLookup[id].map(v => v.numVal).indexOf(min);
		let indexOfMax = rowValuesLookup[id].map(v => v.numVal).indexOf(max);

		if(indexOfMin === -1 || indexOfMax === -1){
			return {min: 'N/A', max: 'N/A'};
		}

		if(type === 'icon' || type === 'scale'){
			try{
				let lookupArray = type === 'icon' ? iconOptions : scaleOptions;
				let lookupKey = type === 'icon' ? selectedIcon : selectedScale;
				let scale = lookupArray.find(el => el.key === lookupKey);
				// console.log(scale);
				if(scale){
					let options = type === 'icon' ? scale.options : scale.scales;
					let matchMin = options.find(el => el.value === min);
					let matchMax = options.find(el => el.value === max);
					if(matchMin){
						min = matchMin.render ? matchMin.render(14) : matchMin.text;
					} else {
						min = 'N/A';
					}
					if(matchMax){
						max = matchMax.render ? matchMax.render(14) : matchMax.text;
					} else {
						max = 'N/A';
					}
					if(type === 'icon'){
						return {min, max};
					} else {
						if(direction === 'higher'){
							return {min, max};
						} else {
							return {min: max, max: min};
						}
					}
				} else {
					return {min: 'N/A', max: 'N/A'};
				}
			} catch(err){
				return {min: 'N/A', max: 'N/A'};
			}
		}


		// console.log(indexOfMin, indexOfMax);
		


		min = rowValuesLookup[id][direction === 'higher' ? indexOfMin : indexOfMax].text;
		max = rowValuesLookup[id][direction === 'higher' ? indexOfMax : indexOfMin].text;

		return {min, max};

	}, [rowValuesLookup]);

	const resolveLeft = (norm) => {
		if(norm === null){
			return null
		} else {
			let perc = norm * 100;
			if(perc === 0){
				return 0;
			} else {
				return `calc(${norm * 100}% - 3px)`
			}
		}
	}

	const rowsAreFocused = React.useMemo(() => {
		return filteredTree.some(el => el.focus);
	}, [filteredTree]);

	const toggleShowColorPicker = () => togglePicker(prev => !prev);

	return (
		<div>
			<Dialog hidden={!showSettings} onDismiss={() => toggleShowSettings(false)}>
				<Slider label="Objective Column Width" value={objColWidth} min={50} max={400} step={5} onChange={(value) => {
					dispatch(editProjectUi({objColWidth: value}))
				}}/>
				<Slider label="Unit Column Width" value={unitColWidth} min={50} max={300} step={5} onChange={(value) => {
					dispatch(editProjectUi({unitColWidth: value}))
				}}/>
				<Slider label="Direction Column Width" value={dirColWidth} min={50} max={200} step={5} onChange={(value) => {
					dispatch(editProjectUi({dirColWidth: value}))
				}}/>
				{/* <Slider label="Alternatives Column Width" value={altColWidth} min={50} max={200} step={5} onChange={(value) => {
					dispatch(editProjectUi({altColWidth: value}))
				}}/> */}
			</Dialog>

			{
				showSelectOneIntro && ctMethod === 'select-one' && <TeachingBubble
					headline="Select One"
					target={'#select-one'}
					hasCloseButton
					closeButtonAriaLabel="Close"
					onDismiss={() => dispatch(editProjectUi({showSelectOneIntro: false}))}
					ariaDescribedBy="example-description1"
					ariaLabelledBy="example-label1"
				>
					Click any alternative column to compare it against other alternatives.
				</TeachingBubble>
			}

		


			<div className='page'>
				<h1 id='trade-off-1' className='section-header ms-fontWeight-semibold'>
					Trade-offs - Which alternative offers the best balance across objectives?
					<IconButton 
						iconProps={{iconName: 'MoreVertical'}} 
						styles={{root: {background: 'none !important'}}}
						onClick={() => dispatch(toggleShowInstructions(!showInstructions))}/>
				</h1>
				<div hidden={!showInstructions} className='mb-64'>
					<h3 className='ms-fontWeight-semibold'>In the end, making decisions requires you to make value-based judgments about which alternative offers the best balance across objectives. Different people may make different trade-offs, because they have different values. Think and talk with others to make sure you understand other perspectives before you choose.</h3>
				</div>
				<div>
					<div className='flex-row' style={{alignItems: 'flex-end', marginBottom: 32}}>
						<div>
							<Label>Formatting Method</Label>
							<div id="step-13">
								<DefaultButton 
									primary={ctMethod === 'select-one'}
									id='select-one'
									className="ms-depth-16"
									styles={{root: {border: 'none', marginRight: '16px'}, rootHovered: {border: 'none'}, rootPressed: {border: 'none'}}} 
									text='Select One' 
									onClick={() => dispatch(toggleCtMethod('select-one'))}/>
								<DefaultButton 
									primary={ctMethod === 'compare-all'} 
									className="ms-depth-16"
									styles={{root: {border: 'none', marginRight: '16px'}, rootHovered: {border: 'none'}, rootPressed: {border: 'none'}}}
									text='Compare All' 
									onClick={() => dispatch(toggleCtMethod('compare-all'))}/>
								<DefaultButton 
									primary={ctMethod === 'weighting'} 
									className="ms-depth-16"
									styles={{root: {border: 'none', marginRight: '16px'}, rootHovered: {border: 'none'}, rootPressed: {border: 'none'}}}
									text='Weighting' 
									onClick={() => dispatch(toggleCtMethod('weighting'))}/>
							</div>
						</div>
						<div hidden={ctMethod === 'weighting'} id="step-15" style={{marginRight: 16}}>
							<Label id='callout-target'>
								<IconButton 
									onClick={toggleShowColorPicker} 
									iconProps={{iconName: "Edit"}} 
									styles={{root: {background: 'none !important', height: 'auto'}
								}} />
								Color Scale
							</Label>
							<Callout isBeakVisible={false} directionalHint={DirectionalHint.leftCenter} hidden={!showPicker} target={'#callout-target'} onDismiss={toggleShowColorPicker}>
								{
									ctMethod === 'select-one' ? SELECT_ONE_COLOR_SCHEMES.map(option => {
										return <div key={option.key} style={{display: 'flex', alignItems: 'center', padding: 4, cursor: 'pointer'}} onClick={() => dispatch(editProjectUi({selectOneScale: option.key}))}>
											<FontIcon iconName={selectOneScale === option.key ? 'SkypeCircleCheck' : 'CircleRing'} style={{marginRight: 6, color: selectOneScale === option.key ? getTheme().palette.green : getTheme().palette.neutralSecondary}} />
											{['better', 'same', 'worse'].map(lookup => {
												return <div key={lookup} style={{padding: '4px 6px', borderRadius: 2, marginRight: 4, background: option.styleLookup[lookup].background, color: option.styleLookup[lookup].color }}>{option.styleLookup[lookup].text}</div>
											})}
										</div>
									}) : COLOR_SCHEMES.map(option => {
										return <div style={{display: 'flex', alignItems: 'center', padding: 4, cursor: 'pointer'}} key={option.key} onClick={() => dispatch(editProjectUi({compAllScale: option.key}))}>
											<FontIcon iconName={compAllScale === option.key ? 'SkypeCircleCheck' : 'CircleRing'} style={{marginRight: 6, color: compAllScale === option.key ? getTheme().palette.green : getTheme().palette.neutralSecondary}} />
											<img style={{borderRadius: 2}} height={30} width={140} src={`https://raw.githubusercontent.com/d3/d3-scale-chromatic/master/img/${option.key}.png`} />
										</div>
									})
								}
							</Callout>
							{
								ctMethod === 'select-one' ? (
									<div className='legend'>
										<div className='cell ms-depth-16 ms-fontWeight-semibold' style={{background: '#004578', color: '#fff'}}>Highlighted</div>
										{
											Object.keys(selectedScale).map(k => {
												const { background, color, text } = selectedScale[k];
												return <div key={k} className='cell ms-depth-16 ms-fontWeight-semibold' style={{background, color}}>{text}</div>
											})
										}
									</div>
								) : (
									<div style={{position: 'relative', width: 300}}>
										<div style={{
											width: 4, 
											height: 32,
											borderRadius: 2, 
											border: '1px solid #333', 
											position: 'absolute',
											display: hoveredValue === null ? 'none' : 'block',						
											left: resolveLeft(hoveredValue), 
											background: '#fff',
											transition: 'left 0.5s'
											}}></div>
										<div className='ms-depth-16' style={{height: 32, overflow: 'hidden', borderRadius: 2, display: 'flex', justifyContent: 'center'}}>
											<img 
												src={`https://raw.githubusercontent.com/d3/d3-scale-chromatic/master/img/${compAllScale}.png`} 
												height={'100%'}
												alt='scale preview' 
												width={'120%'} />
										</div>
										<div style={{display: 'flex', justifyContent: 'space-between', position: 'absolute', width: '100%'}}>
											<span className='ms-fontWeight-semibold'>Worst</span>
											<span className='ms-fontWeight-semibold'>Best</span>
										</div>
									</div>
								)
							}
						</div>
						<div>
							<DefaultButton 
								styles={{root: {border: 'none', minWidth: 0, padding: 4}, icon: {fontWeight: `600 !important`, color: getTheme().palette.neutralSecondary}}}
								className='ms-depth-16'
								iconProps={{iconName: 'Settings'}}
								onClick={() => toggleShowSettings(true)}/>
						</div>
					</div>


					<div className='eliminated-wrapper'>
						<div className='eliminated-list'>
							{eliminatedObjectives.length > 0 && <Label>Hidden Objectives:</Label>}
							{
								eliminatedObjectives.map(obj => <div key={obj.id} className='eliminated-obj ms-depth-16 pointer' onClick={() => dispatch(editNode(obj.id, {eliminated: false}))}>
									<IconButton 
										iconProps={{iconName: 'ReturnToSession', style: {fontSize: 12}}}
										styles={{root: {background: 'none', height: 20}, rootHovered: {background: 'none'}, rootPressed: {background: 'none'}}} />
									{obj.title || 'Unnamed Objective'}
								</div>)
							}
						</div>
						<div className='eliminated-list'>
							{eliminatedAlternatives.length > 0 && <Label>Hidden Alternatives:</Label>}
							{
								eliminatedAlternatives.map(alt => <div key={alt.id} className='eliminated-alt  ms-depth-16 pointer' onClick={() => dispatch(editAlternative(alt.id, {eliminated: false}))}>
									<IconButton 
										iconProps={{iconName: 'ReturnToSession', style: {fontSize: 12}}}
										styles={{root: {background: 'none', height: 20}, rootHovered: {background: 'none'}, rootPressed: {background: 'none'}}} />
									{alt.title || 'Unnamed Alternative'}
								</div>)
							}
						</div>
					</div>



					<div hidden={ctMethod !== 'weighting'}>
						{
							ctMethod === 'weighting' && showWeightingInstructions && <Dialog minWidth={600} hidden={false} dialogContentProps={{title: 'About Weighting', type: 	DialogType.close}} onDismiss={() => dispatch(editProjectUi({showWeightingInstructions: false}))}>
									<p>Sometimes you can learn something by assigning importance weights to each objective. When assigning importance weights, remember to consider both the inherent importance of each objective as well as the magnitude of the difference across the alternatives. For example, if you're buying a vehicle, maybe the purchase price is really important to you in general, but by the time you have your shortlist of alternatives, the difference across them isn't that big - they're all well within your budget. This should affect the weight you assign to the objective of 'purchase price'.</p>
									<p>If you are the only decision maker, weighting may give you insights about what matters in the decision. If you are trying to make a decision within your own organization or department, it may be useful to try to reach agreement on weights to use to choose a preferred alternative.</p>
									<p>However, if you want to make a collaborative or consensus-based decision with a diverse group of people, remember that each person will likely have profoundly different values, and almost certainly will assign different weights to the objectives. In this situation, you could invite people to express their individual weights. The goal would be to invite a deeper conversation about what matters and why, and this may help you identify more creative alternatives. Pressing for consensus on weights in a diverse group is unlikely to be productive.</p>
									<p>If you have a diverse group of people struggling to reach agreement on value-based trade-offs, contact us about Altaviz</p>
								<DialogFooter>
									<DefaultButton text="Close" onClick={() => dispatch(editProjectUi({showWeightingInstructions: false}))} />
								</DialogFooter>
							</Dialog>
						
						}
						<div className='fr'>
							<div style={{marginRight: 4}}>
								{
									filteredTree.map((treeItem, index) => {
										const { min, max } = resolveMinMax(treeItem);
										return <div className='cell obj fr aic ms-depth-16' style={{width: 'max-content', borderLeft: '16px solid ' + treeItem.color}} key={treeItem.id}>
											<div style={{width: 400}} className='fr aic'>
												<IconButton
													id={index === 0 ? 'step-16' : null} 
													styles={{root: {background: 'none'}, rootHovered: {background: 'none', color: 'red'}, rootPressed: {background: 'none', color: 'red'}}}
													onClick={() => dispatch(editNode(treeItem.id, {eliminated: true, focus: false}))}
													iconProps={{iconName: 'Cancel', style: {fontSize: 12}}} />
												<div className='f1 mr-12'>
													{treeItem.title}
													{treeItem.unit ? ' - ' : null}
													{treeItem.unit}
												</div>
												<div className='mr-12'>
													<div className='fr aic'>
														<div style={{width: 48}}>Worst: </div>{min}
													</div>
													<div className='fr aic'>
														<div style={{width: 48}}>Best: </div>{max}
													</div>
												</div>
											</div>
											<Slider 
												styles={{
													root: {width: 200},
													valueLabel: {marginRight: 0}
												}} 
												min={0} 
												max={100}
												value={treeItem.weight} 
												onChange={(value) => dispatch(editNode(treeItem.id, {weight: value}))} />
										</div>
									})
								}
								<div style={{display: 'flex', justifyContent: 'flex-end'}}>
									<DefaultButton styles={{root: {border: 'none'}}} className='ms-depth-16' text="Reset Weights" onClick={() => dispatch(editTree(tree.map(obj => ({...obj, weight: 50}))))} />
									{
										!showChart && <DefaultButton styles={{root: {marginLeft: 4}}} primary className='ms-depth-16' text="Draw Chart" onClick={() => dispatch(editProjectUi({showChart: !showChart}))}/>
									}
									
								</div>
							</div>
							<div className='ms-depth-16' style={{display: showChart ? 'block' : 'none', background: '#fff', width: 'max-content', height: 'max-content', paddingTop: 8}}>
								<div className='fr' style={{height: 300}}>
									{
										filteredAlternatives.map((alt, index) => {
												return <div key={alt.id} style={{width: 80, maxWidth: 80, minWidth: 80}}>
													<StackedColumn 
														altId={alt.id} 
														altIndex={index} 
														objectives={filteredTree} 
														weights={weightedWeightLookup} 
														allData={rowValuesLookup} 
														altData={alt.data} />
												</div>
										})
									}
								</div>
								<div className='fr' style={{borderTop: `3px solid ${getTheme().palette.neutralLight}`, background: '#fff'}}>
									{
										filteredAlternatives.map((alt) => {
											return <div className='tac' key={alt.id} style={{width: 80, maxWidth: 80, minWidth: 80}}>
												<TooltipHost content={alt.subTitle}>
													<div className='pointer ms-fontWeight-semibold' style={{textAlign: 'center', padding: 8}}>
															{alt.title}
													</div>
												</TooltipHost>
												<IconButton
													styles={{root: {background: 'none'}, rootHovered: {background: 'none', color: 'red'}, rootPressed: {background: 'none', color: 'red'}}}
													onClick={() => dispatch(editAlternative(alt.id, {eliminated: true}))}
													iconProps={{iconName: 'Cancel', style: {fontSize: 12}}}/>
											</div>
										})
									}
								</div>
							</div>
						</div>

					</div>





					<div className='pointer' hidden={ctMethod === 'weighting'}>
							<div className='ms-fontWeight-semibold fr aie'>
								<div className='ms-fontWeight-semibold fr aic pb-4'
									style={{minWidth: objColWidth, maxWidth: objColWidth, width: objColWidth}}>
										Objective
										{
											rowsAreFocused && <Link style={{marginLeft: 12}} underline={false} onClick={() => dispatch(editTree(tree.map(el => ({...el, focus: false}))))}>Show All</Link>
										}
								</div>
								{
									showMore && <>	
										<div 
											hidden={!showMore} 
											className='ms-fontWeight-semibold fr aic pb-4' 
											style={{minWidth: unitColWidth, maxWidth: unitColWidth, width: unitColWidth}}>Unit of Measure
										</div>
										<div 
											hidden={!showMore}
											className='ms-fontWeight-semibold fr aic pb-4' 
											style={{minWidth: dirColWidth, maxWidth: dirColWidth, width: dirColWidth}}>Bigger Numbers are
										</div>
									</>
								}
								{ 
									filteredAlternatives.map((a, index) => {

										const selected = a.id === selectedAltId;
										
										return <div key={a.id} style={{
											minWidth: a.width || altColWidth, 
											maxWidth: a.width || altColWidth, 
											width: a.width || altColWidth, 
											textAlign: 'center'
										}}>

											<IconButton 
												id={index === 0 ? 'step-17' : null}
												styles={{root: {background: 'none'}, rootHovered: {background: 'none', color: 'red'}, rootPressed: {background: 'none', color: 'red'}}}
												onClick={() => dispatch(editAlternative(a.id, {eliminated: true}))}
												iconProps={{iconName: 'Cancel', style: {fontSize: 12}}}/>
												<TooltipHost content={a.subTitle}>
													<div className='pb-4 ms-fontWeight-semibold'  onClick={() => setSelectedAltId(selected ? null : a.id)}>{a.title}</div>
												</TooltipHost>
										</div>
									})
								}
							</div>
							<ResizeCol />
							<div id='ct-area'>
								{
									filteredTree.map((row, index) => {
										let rowStyle = {opacity: 1};
										if(rowsAreFocused && !row.focus){
											rowStyle.opacity = 0.2;
										}
										return <React.Fragment key={row.id}>
											<div className='fr row ms-depth-16' style={rowStyle} onDoubleClick={(e) => {e.stopPropagation(); e.preventDefault(); dispatch(editNode(row.id, {focus: !row.focus}))}}>
												<div className='fr aic' style={{minWidth: objColWidth, maxWidth: objColWidth, width: objColWidth}}>
													<IconButton
														id={index === 0 ? 'step-16' : null}
														styles={{root: {background: 'none'}, rootHovered: {background: 'none', color: 'red'}, rootPressed: {background: 'none', color: 'red'}}}
														onClick={() => dispatch(editNode(row.id, {eliminated: true, focus: false}))}
														iconProps={{iconName: 'Cancel', style: {fontSize: 12}}} />
													<div>{row.title}</div>
													{row.description && <TooltipHost maxWidth={200} content={row.description}><Icon className='pointer flag' iconName="Info" styles={{root: {color: getTheme().palette.neutralTertiary, marginLeft: 6}}} /></TooltipHost>}
												</div>
												{
													showMore && <div className='fr aic' style={{minWidth: unitColWidth, maxWidth: unitColWidth, width: unitColWidth}}>
													{
														row.unit || '-'
													}
												</div>
												}
												
												{
													showMore && <div className='fr aic' style={{minWidth: dirColWidth, maxWidth: dirColWidth, width: dirColWidth}}>
													{
														row.type === 'icon' ? "Not applicable" : row.direction === 'higher' ? 'Better' : 'Worse'
													}
												</div>
												}
												
												{
													filteredAlternatives.map((alt, aindex) => {
														let selected = alt.id === selectedAltId;
														let style = {...resolveCellStyle(alt, row), minWidth: alt.width || altColWidth, width: alt.width || altColWidth, maxWidth: alt.width || altColWidth, height: '100%'};

														let cell = <div className='cell tac fr aic jcc ms-fontWeight-semibold' onMouseEnter={(e) => setHoveredValue(style.norm)} style={style}>{alt.data[row.id]}</div>

														if(row.displaySetting === 'harvey'){
															cell = <div className='cell tac fr aic jcc ms-fontWeight-semibold' onMouseEnter={(e) => setHoveredValue(style.norm)} style={style}>
																<HarveyBall value={resolveNorm(alt, row)} size={24} hoverInfo={alt.data[row.id]} />
															</div>
														}

														if(row.displaySetting === 'ring'){
															cell = <div className='cell tac fr aic jcc ms-fontWeight-semibold' onMouseEnter={(e) => setHoveredValue(style.norm)} style={style}>
																<Ring value={resolveNorm(alt, row)} text={alt.data[row.id]} hoverText={alt.data[row.id]} size={26} />
															</div>
														}

														if(row.displaySetting === 'pill'){
															cell = <div className='cell tac fr aic jcc ms-fontWeight-semibold' onMouseEnter={(e) => setHoveredValue(style.norm)} style={style}>
																<Pill value={resolveNorm(alt, row)} size={22} />
															</div>
														}

														if(row.displaySetting === 'dot'){
															cell = <div className='cell tac fr aic jcc ms-fontWeight-semibold' onMouseEnter={(e) => setHoveredValue(style.norm)} style={style}>
																<Dot value={resolveNorm(alt, row)} minSize={4} maxSize={26} />
															</div>
														}

														if(row.type === 'scale'){
															let selectedScaleOptions = scaleOptions.find(o => o.key === row.selectedScale) || scaleOptions[0];
															let selectedValue = selectedScaleOptions.scales && selectedScaleOptions.scales.find(v => v.key === alt.data[row.id]);

															cell = <div className='cell tac fr aic jcc ms-fontWeight-semibold' onMouseEnter={(e) => setHoveredValue(style.norm)} style={style}>{selectedValue && selectedValue.render ? selectedValue.render(20) : selectedValue.text}</div>
														}

														if(row.type === 'rating'){
															// let style = resolveCellStyle(alt, row);
															
															let { background, color, norm } = style;

															let { ratingIcon='FavoriteStarFill', maxRating=5 } = row; 

															cell = <Rating
																min={1}
																max={maxRating}
																readOnly
																rating={alt.data[row.id]}
																icon={ratingIcon}
																onMouseEnter={(e) => setHoveredValue(norm)}
																// onMouseLeave={(e) => setHoveredValue(null)}
																styles={{
																	ratingStarBack: {opacity: '0.3'}, 
																	root: {textAlign: 'center', fontSize: 20, background}, 
																	ratingStarFront: {color}}}
																unselectedIcon={ratingIcon}
															/>
														}

														if(row.type === 'icon'){
															let selectedIconOptions = iconOptions.find(o => o.key === row.selectedIcon) || iconOptions[0];
															let picked = selectedIconOptions.options.find(el => el.key === alt.data[row.id]);
															let { norm } = style;
															cell = picked ? <div onMouseEnter={(e) => setHoveredValue(norm)} style={{...style, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{picked.render(style)}</div> : <div style={style}></div>;
														}

														let note = alt.notes && alt.notes[row.id] ? 
														 <Note id={`o${index}-a${aindex}`} text={alt.notes[row.id]} />
														: null

														return (
															<div id={`obj-${index}-alt-${aindex}`} className='pointer table-cell relative' key={`${row.id}-${alt.id}`} onClick={() => setSelectedAltId(selected ? null : alt.id)}>
																{ note }
																{ cell }
															</div>
														)
													}
													)
												}
											</div>
										</React.Fragment>
									})
								}
							</div>
						</div>
					<div id='bottom'></div>
				</div>
			</div>
		</div>
	)
}

export default TradeOffs;


function Note(props){
	return <TooltipHost  content={props.text} maxWidth={140} calloutProps={{target: `#${props.id}`, isBeakVisible: false}} styles={{root: {height: '100%', width: '100%'}}}>
		<FontIcon id={props.id} className='flag' iconName='CaretTopRightSolid8' style={{opacity: 0.2, position: 'absolute', top: 0, right: 0, fontSize: 12}}  />
	</TooltipHost>
}