import React from 'react';

export default function Hover({children, to=16, from=4, style, scale=false, className}){
  const [hovered, setHovered] = React.useState(false);

  const classNames = React.useMemo(() => {
    return hovered ? `${className} ms-depth-${to}` : `${className} ms-depth-${from}`;
  }, [hovered, to, from, className]);

  const styleMemo = React.useMemo(() => {
    if(scale){
      return {
        ...style,
        transform: hovered ? `scale(${scale})` : 'none',
        transition: `all 0.1s ease-out`
      }
    }
    return style
  }, [style, scale, hovered]);


  return <div className={classNames} style={styleMemo} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
    {children}
  </div>
};