import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { editAlternative, editProjectUi  } from '../../store/actions';

import { ResizableBox } from 'react-resizable';

import 'react-resizable/css/styles.css';

export default function ResizeCol(props){

  const dispatch = useDispatch();

  const { alternatives } = useSelector(state => state.project.data);

  const { objColWidth, unitColWidth, dirColWidth, altColWidth } = useSelector(state => state.projectUi);

  return <div className='fr'>

    <ResizableBox style={{minWidth: objColWidth, maxWidth: objColWidth}} minConstraints={[100, 4]} maxConstraints={[400, 4]} width={objColWidth} height={4} onResize={(e, d) => dispatch(editProjectUi({objColWidth: d.size.width}))} />

    <ResizableBox style={{minWidth: unitColWidth, maxWidth: unitColWidth}} minConstraints={[100, 4]} maxConstraints={[400, 4]} width={unitColWidth} height={4} onResize={(e, d) => dispatch(editProjectUi({unitColWidth: d.size.width}))} />

    <ResizableBox style={{minWidth: dirColWidth, maxWidth: dirColWidth}} minConstraints={[100, 4]} maxConstraints={[400, 4]} width={dirColWidth} height={4} onResize={(e, d) => dispatch(editProjectUi({dirColWidth: d.size.width}))} />

    {
      alternatives.map((alt, index) => {
        if(alt.eliminated && !props.showAll) return null;
        return <ResizableBox key={alt.id} 
          minConstraints={[100, 4]} 
          maxConstraints={[400, 4]}
          width={alt.width || altColWidth}
          style={{minWidth: alt.width || altColWidth, maxWidth: alt.width || altColWidth}}
          height={4} 
          onResize={(e, d) => dispatch(editAlternative(alt.id, {width: d.size.width}))} />
      })
    }

  </div>
}