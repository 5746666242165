import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PrimaryButton, ActionButton, DefaultButton } from '@fluentui/react/lib/Button';
import { TextField, Dialog } from '@fluentui/react';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import Api from '../../../lib/api';
import { setAuth } from '../../../store/actions/auth';

export default function Auth(props){
  if(props.method === 'sign-in'){
    return <SignIn toggleForm={() => props.toggleForm('sign-up')} />
  } else {
    return <SignUp toggleForm={() => props.toggleForm('sign-in')} />
  }
}

const SignIn = withRouter(({history, toggleForm}) => {
  const dispatch = useDispatch();
  const [signInState, editState] = useState({
    email: '',
    password: '',
    message: null,
    messageType: null
  });

  const [forgotPassword, showForgotPassword] = React.useState(false);

  const attemptSignIn = () => {
    const { email, password } = signInState;
    Api.post('/api/v1/users/sign-in', {email, password})
    .then(response => {
      if(response.data.success){
        dispatch(setAuth({authenticated: true, ...response.data.user}));
        localStorage.setItem('lista_x_token', response.headers.lista_x_token);
        history.push('/dashboard')
      } else {
        editState({
          ...signInState,
          messageType: 'error',
          message: response.data.error
        })
      }
    })
    .catch(error => {
      editState({
        ...signInState,
        messageType: 'error',
        message: 'Unable to process request.'
      })
    })
  }

  const sendResetRequest = React.useCallback(() => {
    Api.post('/api/v1/users/request-reset-password', {email: signInState.email})
    .then(res => {
      console.log(res);
      if(!res.data.success) throw Error();
      editState(prev => ({
        ...prev,
        password: '',
        message: `A password reset link was sent to ${signInState.email}`,
        messageType: 'success'
      }));
      showForgotPassword(false);
    })
    .catch(err => {
      editState(prev => ({
        ...prev,
        messageType: 'error',
        message: 'Unable to process request.'
      }))
    })
  }, [signInState]);

  return (
    <Dialog hidden={false} modalProps={{isOpen: true}}>
      <div style={{textAlign: 'center'}}>
        <img src="/compass_logo.jpg" alt="Compass Logo" height={60} className="mb-16"/>
      </div>
      <div className='message-placeholder'>
        {
          signInState.message && <MessageBar styles={{root: {padding: '8px 16px'}}} messageBarType={ MessageBarType[signInState.messageType] }>{signInState.message}</MessageBar>
        }
      </div>
      <TextField
        label="Email:"
        type="email"
        value={signInState.email}
        onChange={(e, email) => editState({...signInState, email})} />
      {
       forgotPassword ? <>
        <PrimaryButton 
            className='sign-in-button' 
            text='Send Reset Request' 
            onClick={sendResetRequest} />
          <ActionButton 
            className='sign-in-button' 
            text='Cancel' 
            onClick={() => showForgotPassword(false)} />
       </> : <>
          <TextField 
            label="Password"
            type="password"
            value={signInState.password}
            onChange={(e, password) => editState({...signInState, password})} />
          <PrimaryButton 
            className='sign-in-button' 
            text='Sign In' 
            onClick={() => attemptSignIn()} />
          <ActionButton 
            text='Not Registered? Sign Up' 
            onClick={() => toggleForm(false)} />
          <ActionButton 
            text='Forgot Password' 
            onClick={() => showForgotPassword(true)} />
       </>
      }
    </Dialog>
  )
})

const SignUp = withRouter(({history, toggleForm}) => {
  const dispatch = useDispatch();
  const [signUpState, editState] = useState({
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    first: '',
    last: '',
    message: null,
    messageType: null
  })

  const attemptSignUp = () => {
    const { email, password, confirmEmail, confirmPassword, first, last } = signUpState;
    if(!email || !confirmEmail || !password || !confirmPassword){
      return editState({
        ...signUpState,
        message: 'Fill in all the fields',
        messageType: 'error'
      })
    }
    if(email !== confirmEmail){
      return editState({
        ...signUpState,
        message: 'Email and confirm email do not match',
        messageType: 'error'
      })
    }
    if(password !== confirmPassword){
      return editState({
        ...signUpState,
        message: 'Password and confirm password do not match',
        messageType: 'error'
      })
    }
    if(email === confirmEmail && password === confirmPassword){
      Api.post('/api/v1/users/sign-up', {email, password, first, last})
      .then(response => {
        if(response.data.success){
          dispatch(setAuth({authenticated: true, ...response.data.user}));
          localStorage.setItem('lista_x_token', response.headers.lista_x_token);
          history.push('/dashboard')
        } else {
          editState(prev => ({
            ...prev,
            messageType: 'error',
            message: response.data.error
          }))
        }
      })
      .catch(error => {
        editState(prev => ({
          ...prev, 
          message: error.response.data.message, 
          messageType: 'error'
        }))
      })
    }
  }

  return (
    <Dialog hidden={false} modalProps={{isOpen: true}}>
      <div style={{textAlign: 'center'}}>
        <img src="/compass_logo.jpg" alt="Compass Logo" height={60} className="mb-16"/>
      </div>
      <div className='message-placeholder'>
        {
          signUpState.message && <MessageBar styles={{root: {padding: '8px 16px'}}} messageBarType={ MessageBarType[signUpState.messageType] }>{signUpState.message}</MessageBar>
        }
      </div>
      <TextField
        label="First Name:"
        type="text"
        id="first-name"
        onChange={(e, first) => editState({...signUpState, first})} />
      <TextField
        label="Last:"
        type="text"
        id="last-name"
        onChange={(e, last) => editState({...signUpState, last})} />
      <TextField
        label="Email:"
        type="email"
        id="email-address"
        onChange={(e, email) => editState({...signUpState, email})} />
      <TextField
        label="Confirm Email:"
        type="email"
        id="confirm-email-address"
        onChange={(e, confirmEmail) => editState({...signUpState, confirmEmail})} />
      <TextField 
        label="Password:"
        type="password"
        id="password"
        onChange={(e, password) => editState({...signUpState, password})} />
      <TextField 
        label="Confirm Password:"
        type="password"
        id="confirm-password"
        onChange={(e, confirmPassword) => editState({...signUpState, confirmPassword})} />
      <PrimaryButton className='sign-up-button' text='Sign Up' onClick={attemptSignUp}/>
      <div className='switch-form'>
        <ActionButton text='Already Registered? Sign In' onClick={() => toggleForm(true)}/>
      </div>
    </Dialog>
  )
});