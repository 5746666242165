import React from 'react';

export default function Dot({value, text, maxSize, minSize}){


  const style = React.useMemo(() => {
    let dif = maxSize - minSize;
    return {
      height: Math.max(dif * value, minSize),
      width: Math.max(dif * value, minSize),
      background: '#000',
      borderRadius: '50%'
    }
  }, [value, maxSize, minSize]);

  if(isNaN(value)){
    return null;
  }

  return <div style={style}>

  </div>

}