import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editAlternative, editAlternatives, addAlternative, toggleShowInstructions } from '../../../store/actions';
import { DefaultButton, IconButton, getTheme, Image, ImageFit, Dialog, DialogType, DefaultEffects } from '@fluentui/react';
import Axios from 'axios';
import { TextField } from '@fluentui/react/lib/TextField';
// import Coach from '../../../components/Coach';
// import Hover from '../../../components/Hover';

const theme = getTheme();

function Alternatives(props){
	const { alternatives } = useSelector(state => state.project.data);
	const { showInstructions } = useSelector(state => state.projectUi);
	const [altImgEditorId, changeId] = React.useState(null);

	React.useEffect(() => {
		return () => changeId(null);
	}, []);

	const handleFileUpload = (e) => {
		// console.log(e.currentTarget.files[0]);
		if(!altImgEditorId) return;

		let file = e.currentTarget.files[0];
		Axios.post('https://www.altaviz.ca/api/upload/listaassets', {contentType: file.type, name: file.name})
		.then(response => {
      if(response.data.success){
        const options = {
          headers: {
            'Content-Type': file.type
          }
        };
        return Axios.put(response.data.signedRequest, file, options)
      } else {
        throw new Error('Unable to process request')
      }
    })
		.then(r => {
      if(r.status === 200){
				dispatch(editAlternative(altImgEditorId, {image: `https://listaassets.s3.amazonaws.com/${file.name}`}))
      }
    })
		.catch(err => {
			alert('Unable to upload file.')
		})
	}; 

	const image = React.useMemo(() => {
		let found = alternatives.find(el => el.id === altImgEditorId);
		return found ? found.image : null;
	}, [altImgEditorId, alternatives]);

	const dispatch = useDispatch();

	const handleChange = useCallback(
		(id, changes) => {
			dispatch(editAlternative(id, changes))
		},
		[dispatch]
	)

	const add = () => dispatch(addAlternative());

	return(
		<React.Fragment>
			{/* <Coach page='alternatives' history={history}/> */}
			<div className='page'>
				{
					altImgEditorId && <Dialog hidden={false} dialogContentProps={{title: "Image", type: DialogType.close, subText: 'Provide an image URL or upload'}} onDismiss={() => changeId(null)}>
							<TextField 
								value={image}
								className='ms-depth-4'
								placeholder='Enter URL'
								onChange={(e, v) => dispatch(editAlternative(altImgEditorId, {image: v}))} />
							<div className='tac ms-fontWeight-semibold mt-8 mb-8'>Or</div>
							<div className='tac'>
								<DefaultButton className='ms-depth-4 pointer' style={{border: 'none', position: 'relative'}} text="Upload" type='file'>
									<input onChange={handleFileUpload} type='file' accept="image/*" id="input" style={{position: 'absolute', opacity: 0.0000009, width: '100%', height: '100%'}} />
								</DefaultButton>
							</div>
					</Dialog>
				}
				<h1 id='demo-11' className='section-header ms-fontWeight-semibold'>
					Alternatives - How can you achieve the objectives?
					<IconButton 
						iconProps={{iconName: 'MoreVertical'}} 
						styles={{root: {background: 'none !important'}}} 
						onClick={() => dispatch(toggleShowInstructions(!showInstructions))}/>
				</h1>
				<div hidden={!showInstructions} className='mb-64'>
					<h3 className='ms-fontWeight-semibold'>Brainstorm a range of alternatives - the different courses of action you could take within the scope of the decision you framed. Give them short labels and a brief description.</h3>
					<h3 className='ms-fontWeight-semibold'>If you design your alternatives with your objectives in mind, you're likely to find creative solutions. This is called <em>value-focused thinking</em>.</h3>
				</div>
				<div className='cards'>
					{
						alternatives.map((alt) => {
							return (<div key={alt.id} className='ms-depth-16 card' style={{background: '#fff', padding: 8}}>
									<div onClick={() => changeId(alt.id)}>
										<Image 
											height={130} 
											width={'100%'}
											styles={{root: {border: `1px solid ${getTheme().palette.neutralQuaternaryAlt}`, borderRadius: 2}}}
											className='mb-4 pointer ms-depth-4'
											imageFit={ImageFit.centerCover}
											loading='lazy'
											src={alt.image || '/placeholder.png'} />
									</div>
									<TextField
										value={alt.title}
										autoComplete="off"
										label='Name'
										// borderless
										styles={{fieldGroup: {background: !alt.title ? theme.palette.themeLighter : '#fff', marginBottom: 4, boxShadow: DefaultEffects.elevation4}}} 
										onChange={(e, title) => handleChange(alt.id, {title})} />
									<TextField 
										multiline
										autoComplete='off'
										autoAdjustHeight
										// borderless
										rows={5}
										label='Detail'
										styles={{
											fieldGroup: {background: !alt.subTitle ? theme.palette.themeLighter : '#fff', boxShadow: DefaultEffects.elevation4}, root: {flex: 1}}} 
										onChange={(e, subTitle) => handleChange(alt.id, {subTitle})} 
										value={alt.subTitle}  />
									<div className='tac'>	
										<IconButton 
											styles={{root: {background: 'none'}, rootHovered: {color: theme.palette.red, background: 'none'}}}
											onClick={() => dispatch(editAlternatives(alternatives.filter(a => a.id !== alt.id)))}
											disabled={alternatives.length === 1}
											iconProps={{iconName: 'Delete'}}/>
									</div>
								</div>
							)
						}
						)
					}
				</div>
				<DefaultButton styles={{root: {marginTop: 16}}} className='ms-depth-16' primary id="demo-12" text='Add Alternative' disabled={alternatives.length >= 10} onClick={add}/>
			</div>
		</React.Fragment>
	)
}

export default Alternatives