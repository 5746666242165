import * as authActions from './authActions';
import Api from '../../lib/api';

export const setAuth = (auth) => {
    return {
        type: authActions.AUTH_SET,
        auth
    }
}

export const logout = () => ({
    type: authActions.AUTH_LOGOUT
})

export const checkToken = (token) => {
    return (dispatch, getState) => {
        Api.get('/api/v1/users/check-token')
        .then(response => {
            if(response.data.success){
                dispatch(setAuth({authenticated: true, ...response.data.user}))
            }
        })
    }
}

export const login = (credentials) => {
    return (dispatch, getState) => {
        // check the credentials on the server
        dispatch(setAuth({
            authenticated: true,
            id: 1, 
            first: 'Clayton', 
            last: 'Schroeder',
            email: 'cschroeder@compassrm.com',
            profileImage: null, 
        }))
    }
}