import React, { useEffect, useCallback, useState } from 'react';
import ProjectCard from './components/ProjectCard';
import TemplateCard from './components/TemplateCard';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getProjects } from '../../store/actions/dashboard';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import Api from '../../lib/api/index';
import './Dashboard.css';
import { generateNode, generateAlt } from '../../factories';
import { carExample } from '../../lib/templates';

function Dashboard(props) {
	const [showTemplates, toggleShowTempates] = useState(false);
	const dispatch = useDispatch();

	const getProjectsOnMount = useCallback(() => {
		dispatch(getProjects())
	}, [dispatch]);

	useEffect(() => {
		getProjectsOnMount()
	}, [getProjectsOnMount]);

	const createProject = (template) => {
		Api.post('/api/v2/projects', {
			tree: [generateNode(), generateNode(), generateNode()],
			alternatives: [generateAlt(), generateAlt(), generateAlt()],
			...template
		})
		.then(response => {
			props.history.push(`/project/${response.data.project.id}/context`);
		})
		.catch(error => {
			alert('Unable to create project')
		})
	}

	const { projects, loading } = useSelector(state => state.dashboard);

	const templates = [
		{
			id: '1',
			name: carExample.name, 
			image: carExample.image, 
			onClick: () => {
				createProject(carExample)
			}
		},
	];

	if(loading){
		return <div className='fr aic jcc fh'>
			<Spinner
				styles={{root: {height: '100%'}, circle: {height: 80, width: 80, borderWidth: 4}, label: {fontSize: 14}}} 
				size={SpinnerSize.large}
			/>
		</div>
	}

	return (
		<div className='dashboard'>
			<div className='container'>
				<h1 className='section-header ms-fontWeight-semibold'>{showTemplates ? 'Templates' : 'Dashboard'}</h1>
				<div className='mb-32'>
					<DefaultButton 
						className='ms-depth-16' 
						primary 
						text="Create Project" 
						onClick={() => createProject()} 
						styles={{root: {marginRight: 16}}}/>
					<DefaultButton 
						className='ms-depth-16' 
						text={showTemplates ? "View Projects" : "View Templates"} 
						styles={{root: {border: 'none'}}} 
						onClick={() => toggleShowTempates(!showTemplates)} />
				</div>
				{
					showTemplates ? (
						<div className='grid pb-64'>
							{
								templates.map(t => <TemplateCard onClick={t.onClick} id={t.id} name={t.name} image={t.image} key={t.id}/>)
							}
						</div>
					) : (
						<div className='grid pb-64'>
							{ projects.map(project => <ProjectCard key={`card-${project.id}`} project={project}/>) }
						</div>
					)
				}
			</div>
		</div>
	)
}

export default Dashboard;