import React from 'react';
import User from './User';
import './Header.css';

function Header(props){
    return (
        <header className='ms-depth-16'>
            <h1>{props.page}</h1>
            { props.children }
            <User />
        </header>
    )
}

export default Header;