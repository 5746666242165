import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editAbout } from '../../../../store/actions'
import { getTheme, IconButton } from '@fluentui/react';
import { Editor, EditorState, RichUtils, getDefaultKeyBinding, KeyBindingUtil, convertFromRaw, convertToRaw } from 'draft-js';
import './TextEditor.css';
// import { editProjectUi } from '../../../../store/actions/projectUi';

const theme = getTheme();

export default function TextEditor() {
  const dispatch = useDispatch();
  const { about } = useSelector(state => state.project.data);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [checked, check] = useState(false);

  const checkExistingEditorState = useCallback(
    () => {
      if(about){
        // state exists in the store
        setEditorState(EditorState.createWithContent(convertFromRaw((about))))
      }
      // if we dont have an inital state to use, use the default empty state
      check(true)
    },
    [about],
  )

  // called on inital mount
  useEffect(() => {
    if(!checked){
      checkExistingEditorState();
    }
  }, [checked, checkExistingEditorState]);

  // checks the editor state for changes and updates the store on a delayed timer
  useEffect(
    () => {
      const delayedStoreUpdate = setTimeout(() => {
        dispatch(editAbout(convertToRaw(editorState.getCurrentContent())))
			}, 500)
			return () => clearTimeout(delayedStoreUpdate);
    },
    [editorState, dispatch]
  )
    
  const { hasCommandModifier } = KeyBindingUtil;

  function handleKeyCommand(command) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  }

  function toggleBlockType(blockType) {
    setEditorState(
      RichUtils.toggleBlockType(
        editorState,
        blockType
      )
    );
  }

  function toggleInlineStyle(inlineStyle){
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    );
  }

  function myKeyBindingFn(e) {
    if (e.keyCode === 83 /* `S` key */ && hasCommandModifier(e)) {
      e.preventDefault();
    }
    return getDefaultKeyBinding(e);
  }

  // If the user changes block type before entering any text, we can
  // either style the placeholder or hide it. Let's just hide it now.
  let className = 'RichEditor-editor';
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      className += ' RichEditor-hidePlaceholder';
    }
  }

  return (
    <div className="RichEditor-root grow ms-depth-16">
      <div className='flex-row'>
        <div className='flex-1 controls'>
          <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={toggleInlineStyle}
          /> 
        </div>
      </div>
      <div className={className} style={{background: !contentState.hasText() ? theme.palette.themeLight : '#fff'}}>
        <Editor
          blockStyleFn={getBlockStyle}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={setEditorState}
          keyBindingFn={myKeyBindingFn}
          spellCheck={true}
        />
      </div>
    </div>
  );
}

// Custom overrides for "code" style.
// const styleMap = {
//   CODE: {
//     backgroundColor: 'rgba(0, 0, 0, 0.05)',
//     fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
//     fontSize: 16,
//     padding: 2,
//   },
// };

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    // case 'textcenter': return 'RichEditor-textcenter';
    default: return null;
  }
}

function StyleButton(props){
  const onToggle = (e) => {
    e.preventDefault();
    props.onToggle(props.style);
  };

  return (
    <IconButton 
      iconProps={props.iconProps} 
      styles={{root: {background: 'none', color: '#333'}, rootChecked: {background: theme.palette.neutralLight, color: theme.palette.themePrimary}, icon: {fontSize: 18}}} 
      checked={props.active} 
      onMouseDown={onToggle} />
  )
}

const BLOCK_TYPES = [
  {label: 'H1', style: 'header-one', iconProps: {iconName: 'Header1'}},
  {label: 'H2', style: 'header-two', iconProps: {iconName: 'Header2'}},
  {label: 'H3', style: 'header-three', iconProps: {iconName: 'Header3'}},
  {label: 'H4', style: 'header-four', iconProps: {iconName: 'Header4'}},
  {label: 'Blockquote', style: 'blockquote', iconProps: {iconName: 'RightDoubleQuote'}},
  {label: 'Bullet List', style: 'unordered-list-item', iconProps: {iconName: 'BulletedList2'}},
  {label: 'Ordered List', style: 'ordered-list-item', iconProps: {iconName: 'NumberedList'}},
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return BLOCK_TYPES.map(block =>
    <StyleButton
      key={block.label}
      active={block.style === blockType}
      label={block.label}
      onToggle={props.onToggle}
      style={block.style}
      iconProps={block.iconProps}
    />
  );
};

var INLINE_STYLES = [
  {label: 'Bold', style: 'BOLD', iconProps: {iconName: 'Bold'}},
  {label: 'Italic', style: 'ITALIC', iconProps: {iconName: 'Italic'}},
  {label: 'Underline', style: 'UNDERLINE', iconProps: {iconName: 'Underline'}},
  // {label: 'Monospace', style: 'CODE', iconProps: {iconName: 'Bold'}},
];

const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return INLINE_STYLES.map(style =>
    <StyleButton
      key={style.label}
      active={currentStyle.has(style.style)}
      label={style.label}
      onToggle={props.onToggle}
      style={style.style}
      iconProps={style.iconProps}
    />
  )
};