import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Icon } from '@fluentui/react/lib/Icon';
import { initGuide, editProjectUi } from '../../store/actions';

function scrollTo(id){
	try {
		document.getElementById(id).scrollIntoView({behavior: 'smooth'})
	} catch(err){
		console.error(err)
	}
};

export default function Guide({history}){
	const dispatch = useDispatch();

	const launchDemo = React.useCallback(() => {
		dispatch(initGuide());
		dispatch(editProjectUi({navClosed: false, coachVisible: true}));
		history.push('/guide/demo/context');
	}, [dispatch, history]);

	const [currentStepId, setCurrentStepId] = useState('step-8');

	const [steps, editSteps] = React.useState([
		{
			id: 'step-1',
			visible: true,
			content: <React.Fragment>
				<div className='material'>
					<h1>Welcome to ListaLite</h1>
					<h2>This short demonstration will show you how we can use a structured approach to make better informed decisions.</h2>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-2',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>Let's supposed the following scenario:</h1>
					<h2>A working parent is deciding what to do about sending their child back to school. Complications of Covid-19 have created uncertainty, but decisions still need to be made.</h2>
					<h2>Where do we start?</h2>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-3',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>What decision are you trying to make and why?</h1>
					<h2>We call this the <strong>Decision Context</strong></h2>
					<p className='ms-fontSize-24'>Think about the problem you're trying to solve and the choice you want to address. It can be useful to frame your decision as a <strong>question</strong>.</p>
					<p className='ms-fontSize-24'>Take time to get the right frame. A common error is framing a decision too narrowly, as in 'whether to' do something. Try to frame the decision in a way that allows a range of creative solutions to be considered while being realistic about constraints.</p>
					<div className='flex-row'>
						<div className='flex-1'>
							<h3>Bad</h3>
							<p className='ms-fontSize-18'>Should I send my kids back to school?</p>
						</div>
						<div className='flex-1'>
							<h3>Better</h3>
							<p className='ms-fontSize-18'>Schools have partially re-opened after the covid-19 outbreak. You're a home-based worker and wondering whether to send your kids back to school part-time. What's the best thing to do about the kids' school right now?</p>
						</div>
					</div>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-4',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>What are the things you care about that you have to balance in this decision?</h1>
					<h2>We refer to these as <strong>Objectives</strong></h2>
					<p className='ms-fontSize-24'>Ask, what do you hope to achieve, or avoid with this decision? What 'measure' will you use to describe how an alternative affects each objective? The objectives and measures you come up with will be used to generate and evaluate alternatives.</p>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-5',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>For example</h1>
					<p className='ms-fontSize-24'>I want to support my kids' <strong>education</strong>, I want to minimize the <strong>risk of infection</strong> to my family, I want to enhance <strong>socializing</strong> opportunities for my kids, and I want to have time to be able to earn <strong>income</strong>.</p>
					<h2>Things I Care About:</h2>
					<ul className='block-list'>
						<li className='ms-fontSize-18'>Education</li>
						<li className='ms-fontSize-18'>Risk of Infection</li>
						<li className='ms-fontSize-18'>Socializing</li>
						<li className='ms-fontSize-18'>Income</li>
					</ul>
					<p className='ms-fontSize-24'>Asking 'why is that important' will help you focus on things that really matter.</p>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-6',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>What options can you think of to address the objectives?</h1>
					<h2>We refer to these as <strong>Alternatives</strong></h2>
					<p className='ms-fontSize-24'>To do this, brainstorm a range of alternatives - the different courses of action you could take, and give them short labels and a brief description.</p>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-7',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>For example</h1>
					<h2>My alernatives are:</h2>
					<ul className='block-list'>
						<li className='ms-fontSize-18'><strong>School:</strong> I could send them back to school</li>
						<li className='ms-fontSize-18'><strong>Home-free:</strong> I could keep the kids at home and let them have free time</li>
						<li className='ms-fontSize-18'><strong>Home school:</strong> I could keep them at home and home-school them</li>
					</ul>
					<p className='ms-fontSize-24'>If you design your alternatives with your objectives in mind, you'll find creative solutions. This is called value-focused thinking.</p>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-8',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>How well does each alternative perform for each objective</h1>
					<h2>We call these <strong>consequences</strong></h2>
					<p className='ms-fontSize-24'>Gather the best available information about the consequences of each alternative for each objective. Make sure to use reliable sources.</p><p className='ms-fontSize-24'>Sometimes it makes sense to use numerical data; other times it may make more sense to choose icons.</p>
					<p className='ms-fontSize-24'>We arrange this data in a table, making it easy to compare the performances of alternatives against our objectives</p>
					<table className='demo-table'>
						<thead>
							<tr>
								<td className='no-style'></td>
								<td className='alt-cell'>School</td>
								<td className='alt-cell'>Home-free</td>
								<td className='alt-cell'>Home school</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='obj-cell'>Education</td>
								<td className='data-cell'>?
								</td>
								<td className='data-cell'>?
								</td>
								<td className='data-cell'>?
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Risk of infection</td>
								<td className='data-cell'>?
								</td>
								<td className='data-cell'>?
								</td>
								<td className='data-cell'>?
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Socializing</td>
								<td className='data-cell'>?
								</td>
								<td className='data-cell'>?
								</td>
								<td className='data-cell'>?
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Income</td>
								<td className='data-cell'>?
								</td>
								<td className='data-cell'>?
								</td>
								<td className='data-cell'>?
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-10',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<p className='ms-fontSize-24'>For example, the school and home-school options are great for education, while home-free performs poorly.</p>
					<table className='demo-table'>
						<thead>
							<tr>
								<td className='no-style'></td>
								<td className='alt-cell'>School</td>
								<td className='alt-cell'>Home-free</td>
								<td className='alt-cell'>Home school</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='obj-cell'>Education</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Risk of infection</td>
								<td className='data-cell'>
									{/* <Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='DislikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='DislikeSolid'/> */}
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Socializing</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Income</td>
								<td className='data-cell'>
									{/* <i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i> */}
								</td>
								<td className='data-cell'>
									{/* <i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i> */}
								</td>
								<td className='data-cell'>
									{/* <i className="fa fa-usd" aria-hidden="true"></i> */}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-11',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<p className='ms-fontSize-24'>The risk of infection is significantly higher at school, while the home-based options minimize the risk.</p>
					<table className='demo-table'>
						<thead>
							<tr>
								<td className='no-style'></td>
								<td className='alt-cell'>School</td>
								<td className='alt-cell'>Home-free</td>
								<td className='alt-cell'>Home school</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='obj-cell'>Education</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Risk of infection</td>
								<td className='data-cell'>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='DislikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Socializing</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Income</td>
								<td className='data-cell'>
									{/* <i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i> */}
								</td>
								<td className='data-cell'>
									{/* <i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i> */}
								</td>
								<td className='data-cell'>
									{/* <i className="fa fa-usd" aria-hidden="true"></i> */}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-12',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<p className='ms-fontSize-24'>School is great for socializing with friends, while the home based options will reduce socializing.</p>
					<table className='demo-table'>
						<thead>
							<tr>
								<td className='no-style'></td>
								<td className='alt-cell'>School</td>
								<td className='alt-cell'>Home-free</td>
								<td className='alt-cell'>Home school</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='obj-cell'>Education</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Risk of infection</td>
								<td className='data-cell'>
									{/* <Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='DislikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='DislikeSolid'/> */}
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Socializing</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Income</td>
								<td className='data-cell'>
									{/* <i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i> */}
								</td>
								<td className='data-cell'>
									{/* <i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i> */}
								</td>
								<td className='data-cell'>
									{/* <i className="fa fa-usd" aria-hidden="true"></i> */}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-13',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<p className='ms-fontSize-24'>Sending the kids to school free's up more time to work, and generate income. While the home based options will have a negative impact on income.</p>
					<table className='demo-table'>
						<thead>
							<tr>
								<td className='no-style'></td>
								<td className='alt-cell'>School</td>
								<td className='alt-cell'>Home-free</td>
								<td className='alt-cell'>Home school</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='obj-cell'>Education</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Risk of infection</td>
								<td className='data-cell'>
									{/* <Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='DislikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='DislikeSolid'/> */}
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Socializing</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/> */}
								</td>
								<td className='data-cell'>
									{/* <Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/> */}
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Income</td>
								<td className='data-cell'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell'>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-14',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<p className='ms-fontSize-24'>Good decisions are based on sound evidence and reasoning. Where you need it, get input from people with recognized expertise in the subject matter. Sometimes you're the expert, but in many kinds of decisions, you should be gathering credible data and judgments from experts.</p>
					<table className='demo-table'>
						<thead>
							<tr>
								<td className='no-style'></td>
								<td className='alt-cell'>School</td>
								<td className='alt-cell'>Home-free</td>
								<td className='alt-cell'>Home school</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='obj-cell'>Education</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Risk of infection</td>
								<td className='data-cell'>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='DislikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Socializing</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Income</td>
								<td className='data-cell'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell'>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-15',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>What are the pros and cons of each alternative? Which alternative offers the best balance across objectives?</h1>
					<h2>We refer to these as <strong>Trade-offs</strong></h2>
					<p className='ms-fontSize-24'>In the end, making decisions requires you to make value-based judgments about which alternative offers the best balance across objectives. Different people may make different trade-offs, because they have different values. Think and talk with others to make sure you understand all the perspectives before you choose.</p>
					<table className='demo-table'>
						<thead>
							<tr>
								<td className='no-style'></td>
								<td className='alt-cell'>School</td>
								<td className='alt-cell'>Home-free</td>
								<td className='alt-cell'>Home school</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='obj-cell'>Education</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Risk of infection</td>
								<td className='data-cell'>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='DislikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Socializing</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Income</td>
								<td className='data-cell'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell'>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-16',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>Which alternative offers the best balance across objectives?</h1>
					<p className='ms-fontSize-24'>We can use color-coding to help see which alternative is good or bad in different ways.</p>
					<table className='demo-table'>
						<thead>
							<tr>
								<td className='no-style'></td>
								<td className='alt-cell'>School</td>
								<td className='alt-cell'>Home-free</td>
								<td className='alt-cell opacity-30'>Home school</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='obj-cell'>Education</td>
								<td className='data-cell good'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell bad'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell good'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Risk of infection</td>
								<td className='data-cell bad'>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell good'>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell good'>
									<Icon iconName='DislikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Socializing</td>
								<td className='data-cell good'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell bad'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell middle'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Income</td>
								<td className='data-cell good'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell middle'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell bad'>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
							</tr>
						</tbody>
					</table>
					<p className='ms-fontSize-24'>School appears to perform strongly across most objectives, but are you willing to risk infection?</p>
					<p className='ms-fontSize-24'>After inspecting these trade-offs, we notice there may be a more creative alternative that provides more balance across the objectives.</p>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-17',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>Let's create a new alternative...</h1>
					<p className='ms-fontSize-24'>We could add a <strong>Bubble</strong>, where a small group of parents could share teaching responsibility.</p>
					<p className='ms-fontSize-24'>This method may not solve everything, by importantly, it isn't bad on anything either.</p>
					<table className='demo-table'>
						<thead>
							<tr>
								<td className='no-style'></td>
								<td className='alt-cell'>School</td>
								<td className='alt-cell'>Home-free</td>
								<td className='alt-cell'>Home school</td>
								<td className='alt-cell'>Bubble</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='obj-cell'>Education</td>
								<td className='data-cell good'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell bad'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell good'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell middle'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Risk of infection</td>
								<td className='data-cell bad'>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell good'>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell good'>
									<Icon iconName='DislikeSolid'/>
								</td>
								<td className='data-cell middle'>
									<Icon iconName='DislikeSolid'/>
									<Icon iconName='DislikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Socializing</td>
								<td className='data-cell good'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell bad'>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell middle'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
								<td className='data-cell middle'>
									<Icon iconName='LikeSolid'/>
									<Icon iconName='LikeSolid'/>
								</td>
							</tr>
							<tr>
								<td className='obj-cell'>Income</td>
								<td className='data-cell good'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell middle'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell bad'>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
								<td className='data-cell middle'>
									<i className="fa fa-usd" aria-hidden="true"></i>
									<i className="fa fa-usd" aria-hidden="true"></i>
								</td>
							</tr>
						</tbody>
					</table>
					<p className='ms-fontSize-24'>ListaLite did not solve the problem, but it helped structure the decision, and create new alternatives to find a better solution.</p>
				</div>
			</React.Fragment>
		},
		{
			id: 'step-18',
			visible: false,
			content: <React.Fragment>
				<div className='material'>
					<h1>Want to try for yourself?</h1>
					<p className='ms-fontSize-24'>Try the interactive demo now</p>
					<PrimaryButton className='large-btn' text="Launch Demo" onClick={launchDemo}/>
				</div>
			</React.Fragment>
		}
	]);

	const next = React.useCallback(
		(nextIndex) => {
			editSteps(prev => {
				return prev.map((step, i) => {
					if(i === nextIndex){
						return {
							...step,
							visible: true
						}
					}
					return step
				})
			});
			const nextId = steps[nextIndex].id;
			setCurrentStepId(nextId);
		},
		[steps],
	);

		React.useEffect(() => {
			scrollTo(currentStepId);
		}, [currentStepId]);

	const last = React.useCallback((lastIndex) => {
		const lastId = steps[lastIndex].id;
		setCurrentStepId(lastId);
	}, [steps]);

	return <div className='guide'>
		{
			steps.map((step, index) => {
				const isFirst = index === 0;
				const isLast = index === (steps.length - 1);
				if(!step.visible){
					return null
				};
				return <div key={step.id} id={step.id} className='full-view flex-col'>
					{step.content}
					<div className='navigation'>
						{
							!isFirst && <DefaultButton disabled={isFirst} className='large-btn' text="Last" onClick={() => last(index - 1)}/>
						}
						{
							!isLast && <PrimaryButton disabled={isLast} className='large-btn' text="Next" onClick={() => next(index + 1)}/>
						}
					</div>
				</div>
			})
		}
	</div>
}