import * as projectActions from './projectActions';
import { setLoading, setSaving, isGuide, editProjectUi } from './projectUi';
import Api from '../../lib/api';

// first thing called when a project is mounted.
// makes api request and returns project related data
export const getProjectData = (id) => {
	return (dispatch, getState) => {
		dispatch(setLoading(true))
		dispatch(editProjectUi({saveHidden: false}));
		Api.get(`/api/v2/projects/${id}`)
		.then(response => {
			if(response.data.success){
				dispatch(setProjectData({data: response.data.data, prev: response.data.data}));
				dispatch(setLoading(false));
			} else {
				dispatch(setLoading(false));
			}
		})
		.catch(error => {
			dispatch(setLoading(false));
			alert('Unable to get project data');
		})
	}
}

export const saveProject = (id) => {
	return (dispatch, getState) => {
		const data = getState().project.data;
		dispatch(setSaving(true));
		Api.put(`/api/v2/projects/${id}`, data)
		.then(response => {
			dispatch(setSaving(false));
			dispatch(setProjectData({data, prev: data}));
			dispatch(editProjectUi({dirty: false}));
		})
		.catch(error => {
			dispatch(setSaving(false));
			alert('Unable to save changes');
		})
	}
}

export const initGuide = () => {
	return (dispatch, getState) => {
		dispatch(setGuideState());
		dispatch(isGuide(true));
		dispatch(setLoading(false));
		dispatch(editProjectUi({saveHidden: true, coachVisible: true}));
	}
}

export const setGuideState = () => ({
	type: projectActions.INIT_GUIDE
})

export const editProjectData = (changes) => ({
	type: projectActions.PROJECT_EDIT_DATA,
	changes
})

export const setProjectData = ({data, prev}) => ({
	type: projectActions.PROJECT_SET_DATA,
	data,
	prev
})

export const editNode = (id, changes) => ({
	type: projectActions.EDIT_NODE,
	id,
	changes
})

export const addAlternative = (position='start') => ({
	type: projectActions.ADD_ALTERNATIVE,
	position
})

export const editAlternative = (id, changes) => ({
	type: projectActions.EDIT_ALTERNATIVE,
	id,
	changes
})

export const editAlternatives = (alternatives) => ({
	type: projectActions.EDIT_ALTERNATIVES,
	alternatives
})

export const editTree = (tree) => ({
	type: projectActions.EDIT_TREE,
	tree
})

export const editAbout = (about) => ({
	type: projectActions.EDIT_ABOUT,
	about
})
