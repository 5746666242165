import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editProjectData, editProjectUi, saveProject } from '../../../store/actions';
import { IconButton, getTheme } from '@fluentui/react';

const theme = getTheme();

function SaveButton({id}){
  const dispatch = useDispatch();
  const { loading, dirty } = useSelector(state => state.projectUi);
  // const { data } = useSelector(state => state.project);

  React.useEffect(() => {
    const setDirty = setTimeout(() => {
      if(dirty){
        dispatch(saveProject(id));
      }
    }, 2000);
    return () => clearTimeout(setDirty);
  }, [dirty, id]);

  return !loading && <IconButton
    styles={{root: {height: 48, width: 48, background: 'none !important'}}}
    disabled={!dirty}
    iconProps={{iconName: 'Save', style: {fontSize: 18}}} 
    onClick={() => dispatch(saveProject(id))} />
}

export default SaveButton;