import { getTheme } from "@uifabric/styling";

export const resolveColorScale = (nature='divergent', option=1 ,quantity=5, bucket=0, direction='higher') => {
  const divergent = [
    {
      3: [
        '#d8b365',
        '#f5f5f5',
        '#5ab4ac',
      ],
      4: [
        '#a6611a',
        '#dfc27d',
        '#80cdc1',
        '#018571',
      ],
      5: [
        '#a6611a',
        '#dfc27d',
        '#f5f5f5',
        '#80cdc1',
        '#018571',
      ],
      6: [
        '#8c510a',
        '#d8b365',
        '#f6e8c3',
        '#f5f5f5',
        '#c7eae5',
        '#5ab4ac',
        '#01665e',
      ],
      7: [
        '#8c510a',
        '#d8b365',
        '#f6e8c3',
        '#f5f5f5',
        '#c7eae5',
        '#5ab4ac',
        '#01665e',
      ],
      8: [
        '#8c510a',
        '#bf812d',
        '#dfc27d',
        '#f6e8c3',
        '#c7eae5',
        '#80cdc1',
        '#35978f',
        '#01665e',
      ],
      9: [
        '#8c510a',
        '#bf812d',
        '#dfc27d',
        '#f6e8c3',
        '#f5f5f5',
        '#c7eae5',
        '#80cdc1',
        '#35978f',
        '#01665e',
      ],
      10: [
        '#543005',
        '#8c510a',
        '#bf812d',
        '#dfc27d',
        '#f6e8c3',
        '#f5f5f5',
        '#c7eae5',
        '#80cdc1',
        '#35978f',
        '#01665e',
        '#003c30',
      ],
    },
    {
      3: [
        '#e9a3c9',
        '#f7f7f7',
        '#a1d76a',
      ],
      4: [
        '#d01c8b',
        '#f1b6da',
        '#b8e186',
        '#4dac26',
      ],
      5: [
        '#d01c8b',
        '#f1b6da',
        '#f7f7f7',
        '#b8e186',
        '#4dac26',
      ],
      6: [
        '#c51b7d',
        '#e9a3c9',
        '#fde0ef',
        '#e6f5d0',
        '#a1d76a',
        '#4d9221',
      ],
      7: [
        '#c51b7d',
        '#e9a3c9',
        '#fde0ef',
        '#f7f7f7',
        '#e6f5d0',
        '#a1d76a',
        '#4d9221',
      ],
      8: [
        '#c51b7d',
        '#de77ae',
        '#f1b6da',
        '#fde0ef',
        '#e6f5d0',
        '#b8e186',
        '#7fbc41',
        '#4d9221',
      ],
      9: [
        '#c51b7d',
        '#de77ae',
        '#f1b6da',
        '#fde0ef',
        '#f7f7f7',
        '#e6f5d0',
        '#b8e186',
        '#7fbc41',
        '#4d9221',
      ],
      10: [
        '#8e0152',
        '#c51b7d',
        '#de77ae',
        '#f1b6da',
        '#fde0ef',
        '#e6f5d0',
        '#b8e186',
        '#7fbc41',
        '#4d9221',
        '#276419',
      ],
    },
    {
      3: [
        '#fc8d59',
        '#ffffbf',
        '#91cf60',
      ],
      4: [
        '#d7191c',
        '#fdae61',
        '#a6d96a',
        '#1a9641',
      ],
      5: [
        '#d7191c',
        '#fdae61',
        '#ffffbf',
        '#a6d96a',
        '#1a9641',
      ],
      6: [
        '#d73027',
        '#fc8d59',
        '#fee08b',
        '#d9ef8b',
        '#91cf60',
        '#1a9850',
      ],
      7: [
        '#d73027',
        '#fc8d59',
        '#fee08b',
        '#ffffbf',
        '#d9ef8b',
        '#91cf60',
        '#1a9850',
      ],
      8: [
        '#d73027',
        '#f46d43',
        '#fdae61',
        '#fee08b',
        '#d9ef8b',
        '#a6d96a',
        '#66bd63',
        '#1a9850',
      ],
      9: [
        '#d73027',
        '#f46d43',
        '#fdae61',
        '#fee08b',
        '#ffffbf',
        '#d9ef8b',
        '#a6d96a',
        '#66bd63',
        '#1a9850',
      ],
      10: [
        '#a50026',
        '#d73027',
        '#f46d43',
        '#fdae61',
        '#fee08b',
        '#d9ef8b',
        '#a6d96a',
        '#66bd63',
        '#1a9850',
        '#006837',
      ],
    }
  ];
  const sequential = [
    {
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: [],
      9: [],
      10: [],
    }
  ];
  
  let colorArray = (nature === 'divergent') ? divergent[option][quantity] : sequential[option][quantity];
  // reverse the array if higher is better
  if(direction === 'lower'){
    colorArray.reverse();
  }
  return {backgroundColor: colorArray[bucket]};
};

export const COLOR_SCHEMES = [
  {text: 'Brown Green', key: 'BrBG'},
  {text: 'Purple Green', key: 'PRGn'},
  {text: 'Pink Green', key: 'PiYG'},
  {text: 'Purple Orange', key: 'PuOr'},
  {text: 'Red Blue', key: 'RdBu'},
  {text: ' Red Grey', key: 'RdGy'},
  {text: 'Red Yellow Blue', key: 'RdYlBu'},
  {text: 'Red Yellow Green', key: 'RdYlGn'},
  {text: 'Spectral', key: 'Spectral'},
  {text: 'Blues', key: 'Blues'},
  {text: 'Greens', key: 'Greens'},
  {text: 'Greys', key: 'Greys'},
  {text: 'Oranges', key: 'Oranges'},
  {text: 'Purples', key: 'Purples'},
  {text: 'Reds', key: 'Reds'}
];

export const SELECT_ONE_COLOR_SCHEMES = [
  {key: 'redGreen', text: 'Green - Red', styleLookup: {
    // default: {background: '#fff', color: '#333'},
    better: {text: 'Better', background: '#66bb6a', color: '#fff'},
    same: {text: 'Same', background: '#8f8f8f', color: '#fff'},
    worse: {text: 'Worse', background: '#ef5350', color: '#fff'},
    error: {text: 'Error', background: '#fff', color: getTheme().palette.redDark}
  }},
  {key: 'blueOrange', text: 'Blue - Orange', styleLookup: {
    // default: {background: '#fff', color: '#333'},
    better: {text: 'Better', background: '#1878d5', color: '#fff'},
    same: {text: 'Same', background: '#8f8f8f', color: '#fff'},
    worse: {text: 'Worse', background: '#ffa726', color: '#333'},
    error: {text: 'Error', background: '#fff', color: getTheme().palette.redDark}
  }},
  {key: 'purpleGold', text: 'Gold - Purple', styleLookup: {
    // default: {background: '#fff', color: '#333'},
    better: {text: 'Better', background: '#ffca28', color: '#333'},
    same: {text: 'Same', background: '#8f8f8f', color: '#fff'},
    worse: {text: 'Worse', background: '#7e57c2', color: '#fff'},
    error: {text: 'Error', background: '#fff', color: getTheme().palette.redDark}
  }},
  {key: 'purpleGreen', text: 'Green - Purple', styleLookup: {
    // default: {background: '#fff', color: '#333'},
    better: {text: 'Better', background: '#66bb6a', color: '#fff'},
    same: {text: 'Same', background: '#8f8f8f', color: '#fff'},
    worse: {text: 'Worse', background: '#7e57c2', color: '#fff'},
    error: {text: 'Error', background: '#fff', color: getTheme().palette.redDark}
  }},
  {key: 'redBlue', text: 'Blue - Red', styleLookup: {
    // default: {background: '#fff', color: '#333'},
    better: {text: 'Better', background: '#1878d5', color: '#fff'},
    same: {text: 'Same', background: '#8f8f8f', color: '#fff'},
    worse: {text: 'Worse', background: '#ef5350', color: '#fff'},
    error: {text: 'Error', background: '#fff', color: getTheme().palette.redDark}
  }},
];

export function LightenDarkenColor(col, amt) {
  
  var usePound = true;

  if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if (b > 255) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);

};

export function convertColor(col, isHex, lightenFactor=50){
  return LightenDarkenColor(isHex ? col : rgbToHex(col), lightenFactor)
};

export function rgbToHex(rgbString) {
  let colorArr = rgbString.substring(4, rgbString.length-1)
  .replace(/ /g, '')
  .split(',')
  .map(a => parseInt(a, 10));

  return "#" + componentToHex(colorArr[0]) + componentToHex(colorArr[1]) + componentToHex(colorArr[2]);
};

export function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};