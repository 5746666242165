import * as dashActions from '../actions/dashboardActions';
// import { createDummyProjects } from '../../pages/dashboard/dashboardUtilities';

const initialState = {
	projectsFetched: false,
	projects: [],
	loading: true,
	message: '',
	error: null,
	filter: '',
	showProjectModal: false
}

const reducer = (state = initialState, action) => {
	switch(action.type){
		case dashActions.DASHBOARD_SET_LOADING:
			return {
				...state,
				loading: action.loading
			}
		case dashActions.DASHBOARD_SET_PROJECTS:
				return {
					...state,
					projects: action.projects
				}
		case dashActions.DASHBOARD_SET_ERROR:
				return {
					...state,
					error: action.error
				}
		case dashActions.DASHBOARD_CHANGE_FILTER:
			return {
				...state,
				filter: action.filter
			};
		case dashActions.DASHBOARD_SHOW_CREATE_PROJECT:
			return {
				...state,
				showProjectModal: action.bool
			}
		case dashActions.DASHBOARD_TOGGLE_FAVORITE:
			return {
					...state,
					projects: state.projects.map(p => {
							if(p.id === action.projectId){
									return {...p, favorite: !p.favorite}
							}
							return p
					})
				}
		case dashActions.DASHBOARD_SORT_PROJECTS_AZ:
			return {
				...state,
				projects: state.projects.sort((a, b) => {
					let aName = a.data.name.toUpperCase();
					let bName = b.data.name.toUpperCase();
					if(aName < bName){ return -1 }
					if(aName > bName){ return 1 }
					return 0
				})
			}
		case dashActions.DASHBOARD_SORT_PROJECTS_ZA:
			return {
				...state,
				projects: state.projects.sort((a, b) => {
					let aName = a.data.name.toUpperCase();
					let bName = b.data.name.toUpperCase();
					if(aName > bName){ return -1 }
					if(aName < bName){ return 1 }
					return 0
				})
			}
		case dashActions.DASHBOARD_SORT_PROJECTS_OLDEST:
			return {
				...state,
				projects: state.projects.sort((a, b) => {
					if(a.data.lastUpdate > b.data.lastUpdate){ return -1 }
					if(a.data.lastUpdate < b.data.lastUpdate){ return 1 }
					return 0
				})
			}
		case dashActions.DASHBOARD_SORT_PROJECTS_LATEST:
			return {
				...state,
				projects: state.projects.sort((a, b) => {
					if(a.data.lastUpdate < b.data.lastUpdate){ return -1 }
					if(a.data.lastUpdate > b.data.lastUpdate){ return 1 }
					return 0
				})
			}
		default: return {...state}
	}
}

export default reducer;

