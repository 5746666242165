import * as alertActions from '../actions/alertActions';

const initialState = {
  messages: []
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case alertActions.SET_ALERT: {
      return {
        ...state,
        messages: state.messages.concat(action.message)
      }
    }
    case alertActions.CLEAR_ALERT: {
      return {
          ...state,
        messages: state.messages.filter(message => message.id !== action.id)
      }
    }
    case alertActions.CLEAR_ALERTS: {
      return {
        initialState
      }
    }
    default: return {...state}
  }
}

export default reducer;

