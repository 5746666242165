import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, NavLink } from 'react-router-dom';
import { getProjectData, initGuide } from '../../store/actions';
import SaveButton from './components/SaveButton';

// project pages
import About from './about/About';
import Objectives from './objectives/Objectives';
import Alternatives from './alternatives/Alternatives';
import Consequences from './consequences/Consequences';
import Home from './home/Home';
import TradeOffs from './tradeoffs/TradeOffs';

import './Project.css';
import { editProjectUi } from '../../store/actions/projectUi';

function Project(props){
	const { match } = props;
	const dispatch = useDispatch();
	const { loading } = useSelector(state => state.projectUi);
	const { data, prev } = useSelector(state => state.project);
	const { id } = match.params;

	const getOnMount = useCallback(() => {
		if(id === 'demo'){
			dispatch(initGuide());
		} else {
			dispatch(getProjectData(id));
		}
	}, [dispatch, id]);
	
	useEffect(() => {
		getOnMount();
	}, [getOnMount, id]);

	useEffect(() => {
		return () => {
			dispatch(editProjectUi({loading: true, dirty: false}));
		}
	}, []);

	useEffect(() => {
		const delayedCheck = setTimeout(() => {
			if(JSON.stringify(prev) !== JSON.stringify(data)){
				dispatch(editProjectUi({dirty: true}));
			}
		}, 1500);
		return () => clearTimeout(delayedCheck);
	}, [data, prev]);

	return (
		<>
			<nav className='ms-depth-8'>
				<div className='links'>
					<div className='link fr'>
						<NavLink to={`/dashboard`}>
							{/* <IconButton iconProps={{iconName: 'HomeSolid'}}/> */}
							<div style={{padding: 12}} className='fr aic jcc'>
								<img alt='logo' src='/favicon.ico' height={30} width={30} />
							</div>
						</NavLink>
					</div>
					<div className='link'>
						<NavLink to={`${match.url}/context`}>
							Context
						</NavLink>
					</div>
					<div className='link'>
						<NavLink to={`${match.url}/objectives`}>
							Objectives
						</NavLink>
					</div>
					<div className='link'>
						<NavLink to={`${match.url}/alternatives`}>
							Alternatives
						</NavLink>
					</div>
					<div className='link'>
						<NavLink to={`${match.url}/consequences`}>
							Consequences
						</NavLink>
					</div>
					<div className='link'>
						<NavLink to={`${match.url}/trade-offs`}>
							Trade-Offs
						</NavLink>
					</div>
				</div>
				<div>
					<SaveButton id={id}/>
				</div>
			</nav>
			{
				loading ? null : (
					<Switch>
						<Route path={`${match.path}/context`} component={About} />
						<Route path={`${match.path}/home`} component={Home} />
						<Route path={`${match.path}/objectives`} component={Objectives} />
						<Route path={`${match.path}/alternatives`} component={Alternatives} />
						<Route path={`${match.path}/consequences`} component={Consequences} />
						<Route path={`${match.path}/trade-offs`} component={TradeOffs}/>
					</Switch>
				)
			}
		</>
	)
}

export default Project;