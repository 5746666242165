import React from 'react';
import TextEditor from './components/TextEditor';
import { TextField } from '@fluentui/react/lib/TextField';
import { IconButton, getTheme, Image, ImageFit, DefaultButton } from '@fluentui/react';
import { useSelector, useDispatch } from 'react-redux';
import { editProjectData, toggleShowInstructions } from '../../../store/actions';
import Axios from 'axios';
// import Coach from '../../../components/Coach';

const theme = getTheme();

function About(props){
	const dispatch = useDispatch();
	const { name, image } = useSelector(state => state.project.data);
	const { showInstructions } = useSelector(state => state.projectUi);
	const handleFileUpload = (e) => {

		let file = e.currentTarget.files[0];
		Axios.post('https://www.altaviz.ca/api/upload/listaassets', {contentType: file.type, name: file.name})
		.then(response => {
      if(response.data.success){
        const options = {
          headers: {
            'Content-Type': file.type
          }
        };
        return Axios.put(response.data.signedRequest, file, options)
      } else {
        throw new Error('Unable to process request')
      }
    })
		.then(r => {
      if(r.status === 200){
				dispatch(editProjectData({image: `https://listaassets.s3.amazonaws.com/${file.name}`}))
      }
    })
		.catch(err => {
			alert('Unable to upload file.')
		})
	}; 
	return(
		<React.Fragment>
			{/* <Coach page='context' history={props.history}/> */}
			<div className='page sm'>
				<h1 id='demo-1' className='section-header ms-fontWeight-semibold'>
					Context - What decision are you trying to make?
					<IconButton
						id='inst-2'
						styles={{root: {background: 'none !important'}}} 
						iconProps={{iconName: 'MoreVertical'}} 
						onClick={() => dispatch(toggleShowInstructions(!showInstructions))}/>
				</h1>
				<div id='inst-1' className='mb-64' hidden={!showInstructions}>
					<h3 className='ms-fontWeight-semibold'>Most decisions can be <em>framed</em>, or scoped, in different ways. Think about the problem you're trying to solve and the choice you want to address.</h3>
					<h3 className='ms-fontWeight-semibold'>Take time to get the right frame. A common error is framing a decision too narrowly, as in <em>whether to</em> do something or not. Try to frame the decision in a way that allows a range of creative solutions to be considered while being realistic about constraints. For example, instead of "should I buy Tom's Ranger or not?", the frame "which vehicle should I buy?" will encourage you to consider alternatives, which usually leads to better choices.</h3>
				</div>
				<div className='fr g16'>
					<div className='f1'>
						<h3 className='ms-fontWeight-semibold'>Cover Image</h3>

						<Image 
							styles={{root: {background: '#fff'}}} 
							imageFit={ImageFit.centerCover} 
							className='ms-depth-16 mb-16' 
							src={image || '/placeholder.png'} 
							height={460} 
							width={'100%'} />
						
						<div className='fr aic g16'>
							<TextField 
								autoComplete='off'
								id='demo-2'
								value={image}
								borderless
								className="ms-depth-16 f1"
								placeholder='Image URL'
								onChange={(e, image) => dispatch(editProjectData({image}))}
								styles={{
									root: {background: !image ? theme.palette.themeLight : '#fff'},
									fieldGroup: {background: 'none'},
									// field: {height: 44, fontSize: 24, lineHeight: 44}
								}} />

								<div className='ms-fontWeight-semibold'>Or</div>
							
							<DefaultButton className='ms-depth-4 pointer' style={{border: 'none', position: 'relative'}} text="Upload" type='file'>
								<input onChange={handleFileUpload} type='file' accept="image/*" className='pointer' style={{position: 'absolute', opacity: 0.0000009, width: '100%', height: '100%'}} />
							</DefaultButton>
						</div>

					</div>
					<div className='f1 h-mc'>
						<h3 className='ms-fontWeight-semibold'>Project Title</h3>
						<TextField 
							autoComplete='off'
							id='demo-2'
							value={name}
							borderless
							className="ms-depth-16"
							onChange={(e, name) => dispatch(editProjectData({name}))}
							styles={{
								root: {marginBottom: 24, padding: 8, background: !name ? theme.palette.themeLight : '#fff'},
								fieldGroup: {height: 44, background: 'none'},
								field: {height: 44, fontSize: 24, lineHeight: 44}
							}} />
						<h3 className='ms-fontWeight-semibold'>Project Description</h3>
						<TextEditor />
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

export default About