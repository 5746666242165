import * as authAction from '../actions/authActions';

const initialState = {
    authenticated: false,
    id: 1,
    first: null,
    last: null,
    email: null,
    profileImage: null,
    organizations: [], 
    projectRole: null,
    organizationRole: null,
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case authAction.AUTH_SET: {
            return {
                ...state,
                ...action.auth
            }
        }
        case authAction.AUTH_LOGOUT: {
            return {
                initialState
            }
        }
        default: return {...state}
    }
}

export default reducer;

