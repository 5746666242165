import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from './pages/home/Home';
import Dashboard from './pages/dashboard/Dashboard';
import Profile from './pages/user/Profile'
import Project from './pages/project/Project';
import ProtectedRoute from './components/ProtectedRoute'
import Guide from './pages/guide/Guide';
import ResetPassword from './pages/ResetPassword';

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/reset-password/:reset_hash" exact component={ResetPassword}/>
          <ProtectedRoute path="/dashboard" component={Dashboard}/>
          <ProtectedRoute path="/profile" component={Profile}/>
          <ProtectedRoute path="/project/:id" component={Project}/>
          <Route path="/guide/:id" component={Project}/>
          <Route path="/demo" component={Guide}/>
          <Redirect to="/"/>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
