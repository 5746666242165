import React from 'react';
import { TooltipHost } from '@fluentui/react';

export default function HarveyBall(props){

  let {size=24, hoverInfo=''} = props;
  let blockStyle = {background: '#333', height: size, width: size, position: 'absolute'};
  let secondBlockStyle = {background: '#333', height: size, width: size, position: 'absolute'};

  let offset = (size / 2);


  if(props.value === null || props.value === 0){
    blockStyle.display = 'none';
    secondBlockStyle.display = 'none';
  }
  if(props.value > 0 && props.value <= 0.333){
    blockStyle.top = `-${offset}px`;
    blockStyle.right = `-${offset}px`;
    secondBlockStyle.display = 'none';
  }
  if(props.value > 0.333 && props.value <= 0.666){
    blockStyle.top = '0px';
    blockStyle.right = `-${offset}px`;
    secondBlockStyle.display = 'none';
  }
  if(props.value > 0.666 && props.value < 1){
    blockStyle.top = '0px';
    blockStyle.right = `-${offset}px`;
    secondBlockStyle.display = 'block';
    secondBlockStyle.bottom = `-${offset}px`;
    secondBlockStyle.left = `-${offset}px`;
  }

  if(props.value === 1){
    blockStyle.top = '0px';
    blockStyle.left = '0px';
    secondBlockStyle.display = 'none';
    // secondBlockStyle.top = `-${offset}px`;
    // secondBlockStyle.left = `-${offset}px`;
  }

  const content = <div style={{height: size, width: size, borderRadius: '50%', boxShadow: `0px 0px 0px 1px #000`, position: 'relative', overflow: 'hidden', background: '#fff'}}>
    <div style={blockStyle}></div>
    <div style={secondBlockStyle}></div>
  </div>

  if(hoverInfo){
    return <TooltipHost content={hoverInfo}>
      {content}
    </TooltipHost>
  }

  return content;
}