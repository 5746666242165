import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DefaultButton, CommandButton } from '@fluentui/react/lib/Button';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { login, logout } from '../../store/actions';

function UserIcon({firstName, lastName, profileImage}){
    return <Persona size={PersonaSize.size32} imageUrl={profileImage} imageInitials={firstName.charAt(0) + lastName.charAt(0)}/>
}

function User(props) {
	const { authenticated, first, last, profileImage } = useSelector(state => state.auth);
	const dispatch = useDispatch()
	if(!authenticated){
		return (
			<DefaultButton 
				text='Login' 
				onClick={() => dispatch(login())}
			/>
		)
	}
	
	return (
		<CommandButton
			className='user'
			text={`${first} ${last}`} 
			onRenderIcon={() => <UserIcon firstName={first} lastName={last} profileImage={profileImage}/>} 
			menuProps={{items: [
				{
					key: 'logout',
					text: 'Logout',
					onClick: () => dispatch(logout())
				},
				{
					key: 'view-profile',
					text: 'Profile',
					onClick: () => props.history.push('/profile')
				}
			]}} 
		/>
	)
}

export default withRouter(User);
