import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jwt from 'jsonwebtoken';
import { setAuth } from '../store/actions';


export default function ProtectedRoute({component: Component, ...rest}){
  const dispatch = useDispatch();

  const token = localStorage.getItem('lista_x_token') || '';
  const user = React.useMemo(() => jwt.decode(token), [token]);
  

  useEffect(() => {
    if(user){
      dispatch(setAuth(user))
    }
  }, [user, dispatch]);
  


  return <Route 
    {...rest} 
    render={(props) => (user ? <Component {...props} /> : <Redirect to='/'/>)} 
  />
}