export const PROJECT_SET_DATA = 'PROJECT_SET_DATA';

// alt actions
export const EDIT_ALTERNATIVE = 'EDIT_ALTERNATIVE';
export const EDIT_ALTERNATIVES = 'EDIT_ALTERNATIVES';
export const ADD_ALTERNATIVE = 'ADD_ALTERNATIVE';

// tree actions
export const EDIT_TREE = 'EDIT_TREE';
export const EDIT_NODE = 'EDIT_NODE';

// about page
export const EDIT_ABOUT = 'EDIT_ABOUT';

export const PROJECT_EDIT_DATA = 'PROJECT_EDIT_DATA';

export const INIT_GUIDE = 'INIT_GUIDE';