export const DASHBOARD_GET_PROJECTS = 'DASHBOARD_GET_PROJECTS';
export const DASHBOARD_SET_LOADING = 'DASHBOARD_SET_LOADING';
export const DASHBOARD_SORT_PROJECTS_AZ = 'DASHBOARD_SORT_PROJECTS_AZ';
export const DASHBOARD_SORT_PROJECTS_ZA = 'DASHBOARD_SORT_PROJECTS_ZA';
export const DASHBOARD_SORT_PROJECTS_LATEST = 'DASHBOARD_SORT_PROJECTS_LATEST';
export const DASHBOARD_SORT_PROJECTS_OLDEST = 'DASHBOARD_SORT_PROJECTS_OLDEST';
export const DASHBOARD_CHANGE_FILTER = 'DASHBOARD_CHANGE_FILTER';
export const DASHBOARD_TOGGLE_FAVORITE = 'DASHBOARD_TOGGLE_FAVORITE';
export const DASHBOARD_SHOW_CREATE_PROJECT = 'DASHBOARD_SHOW_CREATE_PROJECT';
export const DASHBOARD_SET_ERROR = 'DASHBOARD_SET_ERROR';
export const DASHBOARD_SET_PROJECTS = 'DASHBOARD_SET_PROJECTS';