import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { editTree, editNode, toggleShowInstructions } from '../../../store/actions';
import { generateNode } from '../../../factories';
import { DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Callout, getTheme } from '@fluentui/react';

import './Objectives.css';
import { nodeTypes, scaleOptions, iconOptions, ratingOptions } from '../../../lib/templates/scales';


const theme = getTheme();

function resolveScaleKey(objective){
  if(objective.type === 'scale'){
    return objective.selectedScale
  } else
  if(objective.type === 'icon'){
    return objective.selectedIcon
  } else
  if(objective.type === 'rating'){
    return objective.ratingIcon
  } else return objective.displaySetting || 'number'
}

function resolveScaleOptions(objective){
  if(objective.type === 'scale'){
    return scaleOptions
  } else
  if(objective.type === 'icon'){
    return iconOptions
  } else
  if(objective.type === 'rating'){
    return ratingOptions
  } else {
    return [
      {
        key: 'number',
        text: 'Number'
      },
      {
        key: 'harvey',
        text: 'Harvey Balls'
      },
      {
        key: 'pill',
        text: 'Pill'
      },
      {
        key: 'ring',
        text: 'Ring'
      },
      {
        key: 'dot',
        text: 'Dot'
      }
    ]
  }
}

function resolvePlaceholder(objective){
  if(objective.type === 'scale'){
    return 'Select Scale'
  }
  if(objective.type === 'icon'){
    return 'Select Icon'
  }
  if(objective.type === 'rating'){
    return 'Select Icon'
  }
  return ''
}

const objColWidth = {width: 200, maxWidth: 200, minWidth: 200};
const cellTypeWidth = {width: 120, maxWidth: 120, minWidth: 120};
const dirWidth = {width: 160, maxWidth: 160, minWidth: 160};

function Objectives(props){
  const dispatch = useDispatch();

  const { tree } = useSelector(state => state.project.data);
  const {showInstructions} = useSelector(state => state.projectUi);

  const [calloutId, setCalloutId] = React.useState(null);
  
  const addObjective = () => {
    dispatch(editTree(tree.concat(generateNode())))
  };

	return <div className='page'>
        <h1 id='demo-4' className='section-header ms-fontWeight-semibold'>
          Objectives - What are the things you care about?
          <IconButton 
            iconProps={{iconName: 'MoreVertical'}} 
            styles={{root: {background: 'none !important'}}} 
            onClick={() => dispatch(toggleShowInstructions(!showInstructions))}/>
        </h1>
        <div hidden={!showInstructions} className='mb-64'>
          <h3 className='ms-fontWeight-semibold'>Good decisions are guided by an understanding of <em>what matters</em> - the things you care about that could be affected by the decision. These are your 'objectives'. For each objective, define a specific measure or metric you will use to describe how an alternative affects each objective. The objectives and measures you come up with will be used to evaluate alternatives.</h3>
          <h3 className='ms-fontWeight-semibold'>Asking 'why is that important?' will help you focus on things that really matter.</h3>
        </div>

        {/* <section className='ms-depth-16 ms-motion-slideUpIn'> */}
          {
            tree && tree.length > 0 && <div>
                <div className='fr aic mb-8'>
                  <div style={objColWidth} className='ms-fontWeight-semibold cell' id='demo-7-title'>Objective</div>
                  <div style={cellTypeWidth} className='ms-fontWeight-semibold cell' id='demo-7-type'>Cell Type</div>
                  <div style={objColWidth} className='ms-fontWeight-semibold cell' id='demo-7-unit'>Unit of Measure</div>
                  <div style={dirWidth} className='ms-fontWeight-semibold cell' id='demo-7-dir'>Bigger Numbers are</div>
                  <div style={objColWidth} className='ms-fontWeight-semibold cell' id='demo-7-settings'>Display Settings</div>
                </div>
              {
                tree.map((objective, index) => {
                  return (
                    <div key={objective.id} className='fr row aic ms-depth-16'>

                        <div style={objColWidth} className='title'>
                          <TextField
                            borderless
                            autoComplete='off'
                            value={objective.title}
                            styles={{
                              fieldGroup: {
                                background: !objective.title ? theme.palette.themeLighter : '#fff', fontSize: 18
                              },
                              suffix: {
                                background: !objective.title ? theme.palette.themeLighter : '#fff',
                                padding: 0
                              }
                            }}
                            onRenderSuffix={(props) => {
                              return <IconButton id={`obj-desc-${objective.id}`} ariaLabel='Add description' label='Add description' styles={{root: {background: 'none !important'}}} iconProps={{iconName: "TextDocument"}} onClick={() => setCalloutId(objective.id)} />
                            }}
                            onChange={(e, title) => dispatch(editNode(objective.id, {title}))} />

                            {
                              calloutId === objective.id && <Callout styles={{calloutMain : {overflow: 'hidden'}}} hidden={false} onDismiss={() => setCalloutId(null)} target={`#obj-desc-${objective.id}`}>
                                <TextField 
                                  rows={5} 
                                  multiline 
                                  autoAdjustHeight 
                                  borderless
                                  value={objective.description} onChange={(e, description) => dispatch(editNode(objective.id, {description}))} />
                              </Callout>
                            }
                          
                        </div>
                        <div style={cellTypeWidth} className='cell-type'>
                          <Dropdown
                            selectedKey={objective.type}
                            styles={{title: {border: 'none', borderRadius: 0}}}
                            options={nodeTypes}
                            onChange={(e, option) => dispatch(editNode(objective.id, {type: option.key}))} />
                        </div>
                        <div style={objColWidth} className='unit'>
                          <TextField
                            borderless
                            autoComplete='off'
                            value={objective.unit}
                            styles={{fieldGroup: {background: !objective.unit ? theme.palette.themeLighter : '#fff'}}}
                            onChange={(e, unit) => dispatch(editNode(objective.id, {unit}))} />
                        </div>
                        <div style={dirWidth} className='direction'>
                          {
                            objective.type !== 'icon' ? <Dropdown
                              selectedKey={objective.direction}
                              styles={{title: {border: 'none', borderRadius: 0}}}
                              options={[{key: 'higher', text: 'Better'}, {key: 'lower', text: 'Worse'}]}
                              onChange={(e, option) => dispatch(editNode(objective.id, {direction: option.key}))} /> : 
                            <div style={{height: 32}} className='cell fr aic disabled'>Ignore for icons</div>
                          }
                          
                        </div>
                        
                        <div style={objColWidth}>
                          <div className='flex-row'>

                            {
                              objective.type === 'rating' && <TextField 
                                className='flex-1'
                                borderless
                                type='number'
                                value={objective.maxRating}
                                styles={{fieldGroup: {background: !objective.maxRating ? theme.palette.themeLighter : '#fff'}}}
                                onChange={(e, val) => dispatch(editNode(objective.id, {maxRating: val}))}
                                placeholder="Max Rating"
                                min={3} 
                                max={7}/>
                            }
                            <Dropdown
                              className='flex-1'
                              placeholder={resolvePlaceholder(objective)}
                              selectedKey={resolveScaleKey(objective)}
                              styles={{title: {border: 'none', borderRadius: 0}}}
                              options={resolveScaleOptions(objective)}
                              onChange={(e, option) => {
                                if(objective.type === 'scale'){
                                  dispatch(editNode(objective.id, {selectedScale: option.key}));
                                } else 
                                if(objective.type === 'icon'){
                                  dispatch(editNode(objective.id, {selectedIcon: option.key}));
                                } else
                                if(objective.type === 'rating'){
                                  dispatch(editNode(objective.id, {ratingIcon: option.key}));
                                } else {
                                  dispatch(editNode(objective.id, {displaySetting: option.key}));
                                }
                                
                              }}
                            />
                          </div>
                        </div>

                        <div className='center'>
                          <IconButton 
                            iconProps={{iconName: 'Delete'}} 
                            disabled={tree.length === 1}
                            styles={{root: {background: 'none'}, rootHovered: {background: 'none', color: theme.palette.red}}}
                            onClick={() => dispatch(editTree(tree.filter(t => t.id !== objective.id)))} />
                        </div>
                    </div>
                  )
                })
              }
            </div>
          }
          <DefaultButton primary id='demo-6' className='ms-depth-16' text='Add Objective' disabled={tree.length >= 12} onClick={addObjective}/>
        {/* </section> */}
      </div>
}

export default Objectives