import React from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { useDispatch } from 'react-redux';
import { carExample } from '../../../lib/templates';
import { setProjectData, toggleShowInstructions } from '../../../store/actions';
import { toggleCoach } from '../../../store/actions/projectUi';

function Builder (props){
  
  const dispatch = useDispatch();

	return(
		<div className='page'>
			<h1 className='section-header'>
				Welcome
			</h1>
      <div className='options'>
        {/* <div 
          className='option ms-depth-16'
          onClick={() =>  {
            dispatch(toggleCoach(true))
            props.history.push(`/project/${props.match.params.id}/context`)
          }}>
          <div>
            <h1>Step by Step Guide</h1>
            <p>Use a guided example to learn how to use the tool and structure a decision.</p>
          </div>
        </div> */}
        <div className='option ms-depth-16'>
          <div>
            <h1>Start from Template</h1>
            <p>Use an example decision to get a head start, you can edit any aspect of the example.</p>
            <DefaultButton 
              text="Buying a Truck" 
              onClick={() => {
                dispatch(setProjectData({data: carExample}));
                dispatch(toggleCoach(false));
                props.history.push(`/project/${props.match.params.id}/context`);
              }}/>
          </div>
        </div>
        <div 
          className='option ms-depth-16' 
          onClick={() =>  {
            dispatch(toggleCoach(false));
            dispatch(toggleShowInstructions(false));
            props.history.push(`/project/${props.match.params.id}/trade-offs`);
          }}>
          <div>
            <h1>Open Project</h1>
            <p>If you know what you're doing.</p>
          </div>
        </div>
      </div>
		</div>
	)
}

export default Builder