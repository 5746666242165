import React, { Component } from 'react';
import Header from '../components/Header';

class Profile extends Component {
    state = {
        editing: false,
    }
    render(){
        return(
            <div>
                <Header page="My Profile" />
                <main>
                    <p>About me</p>
                </main>
            </div>
        )
    }
}

export default Profile