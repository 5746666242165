import React from 'react';
// import { Grid, Row, Column } from '../../components/layout';
import { DefaultButton } from '@fluentui/react/lib/Button';
import Auth from './components/Auth';
import Hover  from '../../components/Hover';
import './Home.css';

function Home(props) {
	const [showForm, setShowForm] = React.useState(null);
	return(
		<div className='home'>
			<div className="content-container">
					{
						!showForm ? (
							<div>
								<h1 className='ms-fontWeight-semibold home-title title'>Altaviz Lite</h1>
								<h2 className='home-sub-title title'>Structured Decision Making Tool</h2>
								<div className="buttons">
									<Hover style={{marginRight: 48}}>
										<DefaultButton styles={{root: {border: 'none', fontSize: 24, height: 'auto', padding: '1.2rem 2rem'}}} text="Sign In" onClick={() => setShowForm('sign-in')}/>
									</Hover>
									<Hover>
										<DefaultButton styles={{root: {border: 'none', fontSize: 24, height: 'auto', padding: '1.2rem 2rem'}}} text="Sign Up" onClick={() => setShowForm('sign-up')}/>
									</Hover>
								</div>
							</div>
						) : <Auth method={showForm} toggleForm={(method) => setShowForm(method)} />
					}
			</div>
		</div>
	)
}

export default Home;