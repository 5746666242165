import Axios from 'axios';

const Api = Axios.create({
  // baseURL: process.env.REACT_APP_CLIENT_API_BASEURL
});

Api.interceptors.request.use(
  request => {
    request.headers.token = localStorage.getItem('lista_x_token');
    return request
  },
  error => Promise.reject(error)
)

Api.interceptors.response.use(
  response => {
    // response.headers.token = localStorage.getItem('lista_x_token');
    // console.log(response.headers);
    return response
  },
  error => Promise.reject(error)
)

export default Api