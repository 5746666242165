import React from 'react';
import { Link } from 'react-router-dom';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import Hover from '../../../components/Hover';
// import moment from 'moment';

// const passesFilter = (filter, name="") => name.includes(filter) ? true : false;

function ProjectCard({project}){
	const { data } = project;

	return (
		<Hover className="card pointer" scale={1.015}>
			<Link to={{pathname: `/project/${project.id}/context`}}>
				<div >
					<div className="card-image">
						<Image 
							src={data.image || `/placeholder.png`} 
							alt={`Project Cover - ${project.id}`}  
							height={150} 
							width='100%'
							imageFit={ImageFit.centerCover}
						/>
					</div>
					<div className='card-content'>
						<h3 className='card-title ms-fontWeight-semibold'>{data.name}</h3>
					</div>
				</div>
			</Link>
		</Hover>
	)
}

export default ProjectCard;