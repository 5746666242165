import uuid from "uuid/v4";

export const generateNode = (initalValues) => {
	return {
		id: uuid(),
		title: '',
		shortTitle: '',
		description: '',
		unit: '',
		performanceMeasure: '',
		direction: 'higher',
		type: 'number', // either number, scale, or icon
		selectedScale: 'three-reg', // use a 3 pt scale
		selectedIcon: 'thumb-3',
		maxRating: 5,
		ratingIcon: 'FavoriteStarFill',
		files: [],
		images: [],
		comments: [],
		expanded: true,
		children: [],
		useCases: false,
		cases: [],
		useScales: false,
		scales: [],
		tags: [],
		scenarios: [],
		min: null,
		max: null,
		msic: 10,
		msicType: 'relative', // can be relative or absolute
		defineMinMax: false,
		scaleToMinMax: false,
		eliminated: false,
		hideValues: false,
		...initalValues
	}
}

export const generateScale = (i) => {
	return {
		key: uuid(),
		title: '',
		value: 0,
		comment: '',
		asIcon: false,
		icon: ''
	}
}

export const generateCase = () => {
	return {
		key: uuid(),
		title: '',
		comment: '',
	}
}

export const generateComment = () => {
	return {
		key: uuid(),
		comment: '',
		user: null,
		timestamp: null,
		replied: []
	}
}

export const generateAlt = (initalValues) => {
	return {
		id: uuid(),
		title: '',
		shortTitle: '',
		description: '',
		group: '',
		files: [],
		images: [],
		comments: [],
		tags: [],
		scenarios: [],
		eliminated: false,
		data: {},
		...initalValues,
		style: {
			color: null,
			backgroundColor: null,
			borderColor: null,
			borderStyle: null,
			borderWidth: null
		}
	}
}

export const generateScenario = () => {
	return {
		id: uuid(),
		title: '',
		description: '',
	}
}

export const generateMilestone = () => {
	return {
		id: uuid(),
		timestamp: null,
		comments: [],
		message: ''
	}
}

export const guideData = {
	about: {
		blocks: [
			{
				key: '64tbc',
				text: 'Schools have partially re-opened after the covid-19 outbreak. You\'re a home-based worker and wondering whether to send your kids back to school part-time. What\'s the best thing to do about the kids\' school right now?',
				type: 'unstyled',
				depth: 0,
				inlineStyleRanges: [],
				entityRanges: [],
				data: {}
			}
		],
		entityMap: {}
	},
	name: 'Covid-19, Back to School',
	tree: [
		{
			id: 'd0d92099-de4a-49cd-bef1-f6f84e45aac2',
			title: 'education',
			shortTitle: '',
			description: '',
			unit: '',
			performanceMeasure: '',
			direction: 'higher',
			type: 'icon',
			selectedScale: 'three-reg',
			selectedIcon: 'thumb-3',
			maxRating: 5,
			ratingIcon: 'FavoriteStarFill',
			files: [],
			images: [],
			comments: [],
			expanded: true,
			children: [],
			useCases: false,
			cases: [],
			useScales: false,
			scales: [],
			tags: [],
			scenarios: [],
			min: null,
			max: null,
			msic: 10,
			msicType: 'relative',
			defineMinMax: false,
			scaleToMinMax: false,
			eliminated: false,
			hideValues: false
		},
		{
			id: '0c20da82-f3a1-4f2d-91bb-13aa6bfbe39a',
			title: 'risk of infection',
			shortTitle: '',
			description: '',
			unit: '',
			performanceMeasure: '',
			direction: 'lower',
			type: 'icon',
			selectedScale: 'three-reg',
			selectedIcon: 'thumb-down-3',
			maxRating: 5,
			ratingIcon: 'FavoriteStarFill',
			files: [],
			images: [],
			comments: [],
			expanded: true,
			children: [],
			useCases: false,
			cases: [],
			useScales: false,
			scales: [],
			tags: [],
			scenarios: [],
			min: null,
			max: null,
			msic: 10,
			msicType: 'relative',
			defineMinMax: false,
			scaleToMinMax: false,
			eliminated: false,
			hideValues: false
		},
		{
			id: '4dea74fd-2767-4df8-a96b-c632054198f2',
			title: 'socializing',
			shortTitle: '',
			description: '',
			unit: '',
			performanceMeasure: '',
			direction: 'higher',
			type: 'icon',
			selectedScale: 'three-reg',
			selectedIcon: 'thumb-3',
			maxRating: 5,
			ratingIcon: 'FavoriteStarFill',
			files: [],
			images: [],
			comments: [],
			expanded: true,
			children: [],
			useCases: false,
			cases: [],
			useScales: false,
			scales: [],
			tags: [],
			scenarios: [],
			min: null,
			max: null,
			msic: 10,
			msicType: 'relative',
			defineMinMax: false,
			scaleToMinMax: false,
			eliminated: false,
			hideValues: false
		},
		{
			id: 'a37fd264-7417-4b47-854a-f1a9726f266b',
			title: 'income',
			shortTitle: '',
			description: '',
			unit: '',
			performanceMeasure: '',
			direction: 'higher',
			type: 'icon',
			selectedScale: 'three-reg',
			selectedIcon: 'money-3',
			maxRating: 5,
			ratingIcon: 'FavoriteStarFill',
			files: [],
			images: [],
			comments: [],
			expanded: true,
			children: [],
			useCases: false,
			cases: [],
			useScales: false,
			scales: [],
			tags: [],
			scenarios: [],
			min: null,
			max: null,
			msic: 10,
			msicType: 'relative',
			defineMinMax: false,
			scaleToMinMax: false,
			eliminated: false,
			hideValues: false
		}
	],
	alternatives: [
		{
			id: '32d73672-7f89-41aa-a0c2-5167bd12b93e',
			title: 'School',
			shortTitle: '',
			description: '',
			group: '',
			files: [],
			images: [],
			comments: [],
			tags: [],
			scenarios: [],
			eliminated: false,
			data: {
				'd0d92099-de4a-49cd-bef1-f6f84e45aac2': '3',
				'0c20da82-f3a1-4f2d-91bb-13aa6bfbe39a': '3',
				'4dea74fd-2767-4df8-a96b-c632054198f2': '3',
				'a37fd264-7417-4b47-854a-f1a9726f266b': '3'
			},
			style: {
				color: null,
				backgroundColor: null,
				borderColor: null,
				borderStyle: null,
				borderWidth: null
			}
		},
		{
			id: '32b42ff1-1c23-401d-aa51-368ede8cb731',
			title: 'Home-free',
			shortTitle: '',
			description: '',
			group: '',
			files: [],
			images: [],
			comments: [],
			tags: [],
			scenarios: [],
			eliminated: false,
			data: {
				'd0d92099-de4a-49cd-bef1-f6f84e45aac2': '1',
				'0c20da82-f3a1-4f2d-91bb-13aa6bfbe39a': '1',
				'4dea74fd-2767-4df8-a96b-c632054198f2': '1',
				'a37fd264-7417-4b47-854a-f1a9726f266b': '2'
			},
			style: {
				color: null,
				backgroundColor: null,
				borderColor: null,
				borderStyle: null,
				borderWidth: null
			}
		},
		{
			id: '412382ca-2d5b-4785-bcbe-af6669f36fdc',
			title: 'Home school',
			shortTitle: '',
			description: '',
			group: '',
			files: [],
			images: [],
			comments: [],
			tags: [],
			scenarios: [],
			eliminated: false,
			data: {
				'd0d92099-de4a-49cd-bef1-f6f84e45aac2': '3',
				'0c20da82-f3a1-4f2d-91bb-13aa6bfbe39a': '1',
				'4dea74fd-2767-4df8-a96b-c632054198f2': '2',
				'a37fd264-7417-4b47-854a-f1a9726f266b': '1'
			},
			style: {
				color: null,
				backgroundColor: null,
				borderColor: null,
				borderStyle: null,
				borderWidth: null
			}
		}
	]
}