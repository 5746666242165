import * as dashActions from './dashboardActions';
import Api from '../../lib/api';

export const getProjects = () => {
    return (dispatch, getState) => {
        dispatch(setLoading(true))
        Api.get('/api/v2/projects')
        .then(response => {
            dispatch(setLoading(false))
            if(response.data.success){
                dispatch(setProjects(response.data.projects))
            }
        })
        .catch(error => {
            dispatch(setLoading(false))
            dispatch(setError('There was an issue processing your request'))
        })
    }
}

export const setProjects = (projects) => ({
    type: dashActions.DASHBOARD_SET_PROJECTS,
    projects
})

export const setLoading = (bool) => ({
    type: dashActions.DASHBOARD_SET_LOADING,
    loading: bool
})

export const setError = (error) => ({
    type: dashActions.DASHBOARD_SET_ERROR,
    error
})

export const changeFilter = (filter) => ({
    type: dashActions.DASHBOARD_CHANGE_FILTER,
    filter
})

export const toggleFavorite = (projectId) => ({
    type: dashActions.DASHBOARD_TOGGLE_FAVORITE,
    projectId
})

export const sortAZ = () => ({
    type: dashActions.DASHBOARD_SORT_PROJECTS_AZ
})

export const sortZA = () => ({
    type: dashActions.DASHBOARD_SORT_PROJECTS_ZA
})

export const sortLatest = () => ({
    type: dashActions.DASHBOARD_SORT_PROJECTS_LATEST
})

export const sortOldest = () => ({
    type: dashActions.DASHBOARD_SORT_PROJECTS_OLDEST
})

export const showCreateProject = (bool=false) => ({
    type: dashActions.DASHBOARD_SHOW_CREATE_PROJECT,
    bool
})