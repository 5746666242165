import * as actions from '../actions/projectUiActions';

const initialState = {
    editNodeId: null,
    loading: true,
    selectedAltId: null,
    saving: false,
    showInstructions: false,
    showMore: true,
    ctMethod: 'select-one',
    compAllScale: 'RdYlGn',
    selectOneScale: 'redGreen',
    coachVisible: false,
    altColWidth: 100,
    objColWidth: 240,
    unitColWidth: 120,
    dirColWidth: 150,
    guided: false,
    showGuideModal: false,
    textEditorControls: false,
    saveHidden: false,
    navClosed: false,
    showSelectOneIntro: true,
    showWeightingInstructions: true,
    showChart: false,
    dirty: false,
    initalLoad: true
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actions.SET_GUIDE: {
            return {
                ...state,
                guided: action.bool
            }
        }
        case actions.TOGGLE_COACH: {
            return {
                ...state,
                coachVisible: action.bool
            }
        }
        case actions.EDIT_PROJECT_UI: {
            return {
                ...state,
                ...action.changes
            }
        }
        case actions.SET_EDIT_NODE: {
            return {
                ...state,
                editNodeId: action.id
            }
        }
        case actions.TOGGLE_CT_METHOD: {
            return {
                ...state,
                ctMethod: action.method
            }
        }
        case actions.TOGGLE_SHOW_MORE: {
            return {
                ...state,
                showMore: !state.showMore
            }
        }
        case actions.TOGGLE_SHOW_INSTRUCTIONS: {
            return {
                ...state,
                showInstructions: action.bool
            }
        }
        case actions.SAVING: {
			return {
				...state,
				saving: action.saving
			}
		}
        case actions.SET_LOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }
        case actions.SET_SELECTED_ALT_ID: {
            return {
                ...state,
                selectedAltId: action.id
            }
        }
        default: return {...state}
    }
}

export default reducer;

