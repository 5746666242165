import React from 'react';
import { normalize } from './TradeOffs';
import { Callout, DirectionalHint } from '@fluentui/react';

export const colors = ['#4e79a7', '#a0cbe8', '#f28e2b', '#ffbe7d', '#59a14f', '#8cd17d', '#b6992d','#f1ce63', '#499894', '#86bcb6', '#e15759', '#ff9d9a', '#79706e', '#bab0ac', '#d37295', '#fabfd2', '#b07aa1', '#d4a6c8', '#9d7660', '#d7b5a6'];


export default function StackedColumn({objectives=[], allData={}, altData={}, weights={}, altIndex}){

  const [popup, setPopop] = React.useState({value: null, target: null, color: null});
  // console.log(allData, altData, weights);

  const blocks = objectives.map((obj, index) => {
    let val = altData[obj.id] || '';
    let parsedValue = parseFloat(val.replace(/[^\d.-]/g, ''));
    // let value = parseFloat(altDaValue = null;

    let row = allData[obj.id].map(v => v.numVal);
    let min = Math.min(...row);
    let max = Math.max(...row);
    // console.log(min, max, parsedValue);
    let norm = normalize(min, max, parsedValue, obj.direction);
    let weight = weights[obj.id];
    return {
      key: `block${index}-${altIndex}`,
      title: obj.title,
      utility: norm * weight,
      color: obj.color
    }
  }).reverse();

  const onMouseLeave = () => setPopop({value: null, target: null, color: null});

  const onMouseEnter = (value, target, color) => {
    setPopop(prev=> ({...prev, value: value, target: `#${target}`, color: color}));
  }

  const sum = React.useMemo(() => {
    let s = blocks.reduce((a, b) => a + b.utility, 0) * 100;
    s = s.toFixed(1);
    return s;
  }, [blocks]);

  return <div style={{flex: 1, display: 'flex', flexDirection: 'column-reverse', background: '#fff', height: '100%', width: 80}}>
    <Callout 
      hidden={!popup.target}
      styles={{
        root: {padding: '4px 8px', background: '#fff', borderBottom: `4px solid ${popup.color}`},
      }} 
      target={popup.target} 
      isBeakVisible={false} 
      directionalHint={DirectionalHint.rightCenter}>
        {popup.value}
    </Callout>
    {
      blocks.map(block => {
        return <div key={block.key} id={block.key} onMouseEnter={(e) => onMouseEnter(`${block.title}: ${(block.utility * 100).toFixed(1)}%`, block.key, block.color)} onMouseLeave={onMouseLeave} style={{
          background: block.color,
          width: 60,
          margin: '0 auto',
          height: `${block.utility * 100}%`, 
          transition: 'height 0.25s ease-in-out'
        }}></div>
      })
    }
    <div className='tac ms-fontWeight-semibold'>{`${sum}%`}</div>
  </div>
}