import React from 'react';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import Hover from '../../../components/Hover';

function TemplateCard({name, image, onClick}){

	return (
		<Hover className="card pointer" scale={1.015}>
			<div onClick={onClick}>
				<div className="card-image">
					<Image 
						src={ image } 
						alt={`Project Coverro`}  
						height={150} 
						width='100%'
						imageFit={ImageFit.cover}
					/>
				</div>
				<div className='card-content'>
					<h3 className='card-title ms-fontWeight-semibold'>{name}</h3>
				</div>
			</div>
		</Hover>
	)
}

export default TemplateCard;