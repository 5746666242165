import React from 'react';
import { Progress } from 'antd';
import { getTheme, TooltipHost } from '@fluentui/react';
import 'antd/dist/antd.css';

export default function Ring({value, size=32, text='', hoverText=''}){
  if(isNaN(value)) return null;

  const val = Math.round(value * 100);

  const content = <div style={{width: size, height: size, position: 'relative', background: '#fff', boxShadow: '0px 0px 0px 1px #000', borderRadius: '50%'}}>
    <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, boxShadow: `inset 0px 0px 0px 4px #000`, borderRadius: '50%'}}>

    </div>
    <Progress showInfo={false} width={size} strokeLinecap='square' type='circle' percent={val} format={() => text} strokeColor={'#000'} trailColor={getTheme().palette.neutralLight} strokeWidth={12} />
  </div>
  if(hoverText){
    return <TooltipHost content={hoverText}>{content}</TooltipHost>
  }
  return content
}